import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardLoader } from "../../../../../components/common";
import Card from "../../../../../components/common/Card";
import { GET_USER_DASHBOARD } from "../../../../../store/apis/actionTypes";
import DashboardLayoutWrapper from "../BidderLayout";

export default function VendorBidderDashboard() {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // DASHBOARD DATA
  const { getDashboardData, getDashboardDataLoading } = useSelector(
    (state) => ({
      getDashboardData: state?.UserDashboard?.data?.itemList,
      getDashboardDataLoading: state?.UserDashboard?.loading,
    })
  );

  useEffect(() => {
    if (!getDashboardData) {
      dispatch({
        type: GET_USER_DASHBOARD,
      });
    }
  }, [dispatch]);

  return (
    <DashboardLayoutWrapper>
      {getDashboardDataLoading && <DashboardLoader />}

      <div className="cardGroup">
        {(getDashboardData ?? [])?.map((item, index) => (
          <Card
            key={index}
            label={item?.name}
            path={item?.redirectionLink}
            number={item?.count}
          />
        ))}
      </div>
    </DashboardLayoutWrapper>
  );
}
