import { Footer, Header } from '../../../components/common';

export default function OurSolutions() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />
      <main className="section_our_solutions">
        <section className="our_solutions_container">
          <h1 className="quote">Our Solutions</h1>

          <div className="solutionsBody">
            <div className="body">
              <div className="solutionWrapper">
                <p className="primaryHeading">
                  This platform integrates and exploits many of the latest and forward-looking technologies like artificial intelligence (AI) and
                  Internet of Things (IoT) to provide an extremely user friendly interface to fulfill its purpose.
                </p>
                <p className="secondaryHeading">For customers – vessel owners and vessel managers:</p>

                <ul className="solutions">
                  <li>Reach the right service provider for vessels requirements</li>
                  <li>Ready comparison between proposals from all eligible service providers</li>
                  <li>Proposal comparisons based not only on quoted costs but also on competence of the proposed engineer</li>
                  <li>Enhanced possibility of a first-time fix</li>
                  <li>Direct savings of around 25% on budgeted maintenance costs</li>
                  <li>One stop solution for all your service needs</li>
                </ul>
              </div>

              <div className="solutionWrapper">
                <p className="secondaryHeading">For our partners:</p>

                <ul className="solutions">
                  <li>Exponentially increase your business profitability</li>
                  <li>Expose your business to all vessel owners and managers worldwide</li>
                  <li>Engage in a fair bidding process for every job of interest</li>
                  <li>Exercise differential pricing methodology based on demand</li>
                  <li>Exponentially raise your engineer utilization rate</li>
                  <li>Upload service report and raise invoices with a click of a button</li>
                  <li>Eliminate disputes raised on invoices</li>
                  <li>Improvise cash flow by getting paid on time</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
