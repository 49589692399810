import { Footer, Header } from '../../../components/common';

export default function TermsOfService() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />
      <main className="section_termOfServices">
        <section className="termOfServices_container">
          <div className="termServicesHeading">Terms of Service</div>
          <div
            style={{
              border: '1px solid black',
              padding: '18px 30px',
              borderRadius: '12px',
            }}
          >
            <b className="termOfServicesPrimaryHeading">Welcome to Ath Marine.com.</b>

            <p className="termOfServicesText">
              The following terms of service (these "Terms of Service"), govern your access to and use of the Ath Marine website and mobile
              application, including any content, functionality and services offered on or through www.AthMarine.com or the Ath Marine mobile
              application (the "Site") by Ath Marine Pte Ltd Singapore., referred here to as "Ath Marine" "we" or "us" and “you” or “user” means you
              as a user of the Site. Please read the Terms of Service carefully before you start to use the Site. By using the Site, opening an
              account or by clicking to accept or agree to the Terms of Service when this option is made available to you, you accept and agree, on
              behalf of yourself or on behalf of your employer or any other entity (if applicable), to be bound and abide by these Terms of Service,
              Ath Marine Payment Terms, found here (“Payment Terms”), and our Privacy Policy, found here, each of which is incorporated herein by
              reference. If you do not want to agree to these Terms of Service or the Privacy Policy, you must not access or use the Site. For more
              detailed policies surrounding the activity and usage on the Site, please access the designated articles herein. This Site is offered and
              available to users who are 18 years of age or older. If you are under 18 you may not use this Site or the Ath Marine services. By using
              this Site, you represent and warrant that you are of legal age to form a binding contract and meet all of the foregoing eligibility
              requirements. If you do not meet all of these requirements, you must not access or use the Site. Our Support team is available if you
              have any questions regarding the Site or Terms of Service. Contacting our Support team can be performed by submitting a request to
              marketing@athmarine.com.
            </p>
            <p className="termOfServicesText">
              The original language of these Terms of Service, as well as all other texts throughout the Site, is English. In case of conflicts
              between the original English version and any translation, the English version shall prevail.
            </p>

            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Overview (Main terms, in a nutshell)</b>
            </p>
            <ul>
              <li className="termOfServicesListing">
                Only registered users may transact on Ath Marine. In registering for an account, you agree to provide us with accurate, complete, and
                updated information and must not create an account for fraudulent or misleading purposes. You are solely responsible for any activity
                on your account and for maintaining the confidentiality and security of your password. We are not liable for any acts or omissions by
                you in connection with your account.
              </li>
              <li className="termOfServicesListing">For fees and payments please read the Payment Terms</li>
              <li className="termOfServicesListing">
                Partners must fulfil their orders and may not cancel orders on a regular basis or without cause. Cancelling orders will affect
                Partners’ reputation and status.
              </li>
              <li className="termOfServicesListing">
                Partners gain ratings based on their qualifications, experience, performance, and reputation. Advanced levels provide their owners
                with benefits, including offering services for higher prices.
              </li>
              <li className="termOfServicesListing">
                Users may not offer or accept payments using any method other than placing an order through AthMarine.com.
              </li>
              <li className="termOfServicesListing">
                Ath Marine retains the right to use all delivered services for Ath Marine marketing and promotion purposes.
              </li>
              <li className="termOfServicesListing">
                We care about your privacy. You can read our Privacy Policy here. The Privacy Policy is a part of these Terms of Service and
                incorporated herein by reference.
              </li>
              <li className="termOfServicesListing">
                Users undertake to comply with Ath Marine's Standards, which are a set of behaviour rules and guidelines, applicable to the Ath Marine
                community and marketplace in addition to these Terms of Service, as updated from time to time.
              </li>
            </ul>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Disputes and Cancellations</p>
            </b>
            <p className="termOfServicesText">
              We encourage our Customers and Partners to try and settle conflicts amongst themselves. If for any reason this fails after using the
              Resolution Center or if you encounter non-permitted usage on the Site, users can contact Ath Marine's Support for assistance by writing
              to marketing@athmarine.com. For more information about disputes, Order cancellations and refunds please refer to the Payment Terms.
            </p>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">User Conduct and Protection</p>
            </b>
            <p className="termOfServicesText">
              Ath Marine enables customers around the world to purchase nearly any service they need at an unbeatable value. Services offered on Ath
              Marine reflect the diversity of an expanding economy. Members of the Ath Marine community communicate and engage through orders, social
              media, and on Ath Marine’s Community Forums.
            </p>
            <p className="termOfServicesText">
              Ath Marine maintains a friendly, community spirited, and professional environment. Users should keep to that spirit while participating
              in any activity or extensions of Ath Marine. This section relates to the expected conduct users should adhere to while interacting with
              each other on Ath Marine.
            </p>
            <p className="termOfServicesText">
              To report a violation of our Terms of Service, User Misconduct, or inquiries regarding your account, please contact our Support team by
              writing to marketing@athmarine.com.
            </p>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Basics</p>
            </b>
            <ul>
              <li className="termOfServicesListing">
                To protect our users privacy, user identities are kept anonymous, where possible. Requesting or providing Email addresses, Skype/IM
                usernames, telephone numbers or any other personal contact details to communicate outside of Ath Marine in order to circumvent or
                abuse the Ath Marine messaging system or Ath Marine platform is not permitted.
              </li>
              <li className="termOfServicesListing">
                Any necessary exchange of personal information required to continue a service may be exchanged in the pages assigned within the
                application.
              </li>
              <li className="termOfServicesListing">
                Ath Marine does not provide any guarantee of the level of service offered to Customers. Customers may use the ratings and feedback, if
                given, for the buyers to assess the competence of our Partner, before engaging them for service.
              </li>
              <li className="termOfServicesListing">
                Ath Marine does not provide protection or recognise any agreements, whatsoever, in interactions outside of the Ath Marine platform.
              </li>
              <li className="termOfServicesListing">
                All communication, information and file exchanges must be performed exclusively on Ath Marine's platform.
              </li>
              <li className="termOfServicesListing">
                Rude, abusive, improper language, or violent messages will not be tolerated and may result in an account warning or the
                suspension/removal of your account.
              </li>
              <li className="termOfServicesListing">
                Ath Marine is open to everyone. You undertake not to discriminate against any other user based on gender, race, age, religious
                affiliation, sexual orientation or otherwise and you acknowledge that such discrimination may result in the suspension/removal of your
                account.
              </li>
              <li className="termOfServicesListing">
                Users may not submit proposals or solicit parties introduced through Ath Marine to contract, engage with, or pay outside of Ath
                Marine.
              </li>
            </ul>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Orders</p>
            </b>
            <ul>
              <li className="termOfServicesListing">
                Users with the intention to defame competing Partners by ordering from competing services will have their reviews removed or further
                account status related actions determined by review by our relevant team.
              </li>
              <li className="termOfServicesListing">
                Users are to refrain from spamming or soliciting previous Customers or Partners to pursue removing/modifying reviews or cancelling
                orders that do not align on Order Cancellation or Feedback policies.
              </li>
            </ul>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Reporting Violations</p>
            </b>
            <p className="termOfServicesText">
              If you come across any content that may violate our Terms of Service, you should report it to us through the appropriate channels
              created to handle those issues as outlined in our Terms of Service. All cases are reviewed by our relevant team. To protect individual
              privacy, the results of the investigation are not shared. You can review our Privacy Policy for more information.
            </p>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Violations</p>
            </b>
            <p className="termOfServicesText">
              Users may receive a warning to their account for violations of our Terms of Service or any user misconduct reported to our Trust and
              Safety team. A warning will be sent to the user's email address and will be displayed for such user on the Site. Warnings do not limit
              account activity but can lead to your account permanently disabled based on the severity of the violation.
            </p>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Non-Permitted Usage</p>
            </b>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Adult Services & Pornography -</b> Ath Marine does not allow any exchange of adult oriented
              or pornographic materials and services.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Inappropriate Behavior & Language -</b> Communication on Ath Marine should be friendly,
              constructive, and professional. Ath Marine condemns bullying, harassment, and hate speech towards others. We allow users a medium for
              which messages are exchanged between individuals, a system to rate orders, and to engage on larger platforms such as our Community Forum
              and Social Media pages.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Phishing and Spam -</b> Members security is a top priority. Any attempts to publish or send
              malicious content with the intent to compromise another member’s account or computer environment is strictly prohibited. Please respect
              our members privacy by not contacting them with offers, questions, suggestions, or anything which is not directly related to their
              services.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Privacy & Identity -</b> You may not publish or post other people's private and confidential
              information. Any exchange of personal information required for the completion of a service must be provided in the relevant page.
              Partners further confirm that whatever information they receive from the Customer, which is not public domain, shall not be used for any
              purpose whatsoever other than for the delivery of the work to the Customer. Any users who engage and communicate of Ath Marine will not
              be protected by our Terms of Service.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Authentic Ath Marine Profile -</b> You may not create a false identity on Ath Marine,
              misrepresent your identity, create a Ath Marine profile for anyone other than yourself (a real person), or use or attempt to use another
              user’s account or information; Your profile information, including your description, skills, location, etc., while may be kept
              anonymous, must be accurate and complete and may not be misleading, illegal, offensive, or otherwise harmful. Ath Marine reserves the
              right to require users to go through a verification process in order to use the Site (whether by using ID, phone, camera, etc.).
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Intellectual Property Claims -</b> Ath Marine will respond to clear and complete notices of
              alleged copyright or trademark infringement, and/or violation of third party's terms of service.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Fraud / Unlawful Use -</b> You may not use Ath Marine for any unlawful purposes or to
              conduct illegal activities.
            </p>

            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Abuse and Spam</p>
            </b>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Multiple Accounts -</b> To prevent fraud and abuse, users are limited to one active Ath
              Marine account. Any additional account determined to be created to circumvent guidelines, promote competitive advantages, or mislead the
              Ath Marine community will be disabled. Mass account creation may result in disabling of all related accounts. Note: any violations of
              Ath Marine's Terms of Service is a cause for permanent suspension of all accounts.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Targeted Abuse -</b> We do not tolerate users who engage in targeted abuse or harassment
              towards other users on Ath Marine. This includes creating new multiple accounts to harass members through our message or ordering
              system.
            </p>
            <p className="termOfServicesText">
              <b className="termOfServicesPrimaryHeading">Selling Accounts -</b> You may not buy or sell Ath Marine accounts.
            </p>
            <b className="termOfServicesPrimaryHeading">
              <p className="termOfServicesText">Proprietary Restrictions</p>
            </b>

            <>
              Got it! Here’s the updated HTML with the `className` attributes removed, but with inline styles preserved: ```html
              <p className="termOfServicesText">
                The Site, including its general layout, look and feel, design, information, content, and other materials available thereon, is
                exclusively owned by Ath Marine and protected by copyright, trademark, and other intellectual property laws. Users have no right, and
                specifically agree not to do the following with respect to the Site or any part, component or extension of the Site (including its
                mobile applications): (i) copy, transfer, adapt, modify, distribute, transmit, display, create derivative works, publish or reproduce
                it, in any manner; (ii) reverse assemble, decompile, reverse engineer or otherwise attempt to derive its source code, underlying
                ideas, algorithms, structure or organization; (iii) remove any copyright notice, identification or any other proprietary notices; (iv)
                use automation software (bots), hacks, modifications (mods) or any other unauthorized third-party software designed to modify the
                Site; (v) attempt to gain unauthorized access to, interfere with, damage or disrupt the Site or the computer systems or networks
                connected to the Site; (vi) circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections
                of the Site; (vii) use any robot, spider, crawlers or other automatic device, process, software or queries that intercepts, “mines,”
                scrapes or otherwise accesses the Site to monitor, extract, copy or collect information or data from or through the Site, or engage in
                any manual process to do the same, (viii) introduce any viruses, trojan horses, worms, logic bombs or other materials that are
                malicious or technologically harmful into our systems, (ix) use the Site in any manner that could damage, disable, overburden or
                impair the Site, or interfere with any other users’ enjoyment of the Site or (x) access or use the Site in any way not expressly
                permitted by these Terms of Service. Users also agree not to permit or authorize anyone else to do any of the foregoing.
              </p>
              <p className="termOfServicesText">
                Except for the limited right to use the Site according to these Terms of Service, Ath Marine owns all right, title and interest in and
                to the Site (including any and all intellectual property rights therein) and you agree not to take any action(s) inconsistent with
                such ownership interests. We reserve all rights in connection with the Site and its content (other than UGC) including, without
                limitation, the exclusive right to create derivative works.
              </p>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  Feedback Rights
                </p>
              </b>
              <p className="termOfServicesText">
                To the extent that you provide Ath Marine with any comments, suggestions or other feedback regarding the Ath Marine platform or the
                Site as a whole, as well as other Ath Marine products or services (collective, the “Feedback”), you will be deemed to have granted Ath
                Marine an exclusive, royalty-free, fully paid up, perpetual, irrevocable, worldwide ownership rights in the Feedback. Ath Marine is
                under no obligation to implement any Feedback it may receive from users.
              </p>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  Confidentiality
                </p>
              </b>
              <p className="termOfServicesText">
                Partners should recognize that there might be a need for Customers to disclose certain confidential information to be used by Partners
                for the purpose of delivering the ordered service, and to protect such confidential information from unauthorized use and disclosure.
                Therefore, Partners agree to treat any information received from Customers as highly sensitive, top secret and classified material.
                Without derogating from the generality of the above, Partners specifically agree to (i) maintain all such information in strict
                confidence; (ii) not disclose the information to any third parties; (iii) not use the information for any purpose except for
                delivering the ordered work; and (vi) not to copy or reproduce any of the information without the Customer's permission.
              </p>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  General Terms
                </p>
              </b>
              <ul style={{ marginLeft: '1.3rem' }}>
                <li className="termOfServicesListing">
                  Ath Marine reserves the right to put any account on hold or permanently disable accounts due to breach of these Terms of Service or
                  due to any illegal or inappropriate use of the Site or services.
                </li>
                <li className="termOfServicesListing">Violation of Ath Marine's Terms of Service may get your account disabled permanently.</li>
                <li className="termOfServicesListing">Users with disabled accounts will not be able to transact on Ath Marine platform.</li>
                <li className="termOfServicesListing">
                  Users who have violated our Terms of Service and had their account disabled may contact our Support team for more information
                  surrounding the violation and status of the account.
                </li>
                <li className="termOfServicesListing">
                  Users have the option to enable account Security features to protect their account from any unauthorized usage.
                </li>
                <li className="termOfServicesListing">
                  Users must be able to verify their account ownership through Support by providing materials that prove ownership of that account.
                </li>
                <li className="termOfServicesListing">
                  Disputes should be handled using Ath Marine's dispute resolution procedures or by contacting Ath Marine Support at
                  marketing@athmarine.com.
                </li>
                <li className="termOfServicesListing">
                  Ath Marine may make changes to its Terms of Service from time to time. When these changes are made, Ath Marine will make a new copy
                  of the terms of service available on this page.
                </li>
                <li className="termOfServicesListing">
                  You understand and agree that if you use Ath Marine after the date on which the Terms of Service have changed, Ath Marine will treat
                  your use as acceptance of the updated Terms of Service.
                </li>
              </ul>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  User Generated Content
                </p>
              </b>
              <p className="termOfServicesText">
                User Generated Content ("UGC") refers to the content added by users as opposed to content created by the Site. All content uploaded to
                Ath Marine by our users (Customers and Partners) is User Generated Content. Ath Marine does not check user uploaded/created content
                for appropriateness, violations of copyright, trademarks, other rights or violations and the user uploading/creating such content
                shall be solely responsible for it and the consequences of using, disclosing, storing, or transmitting it. By uploading to, or
                creating content on, the Ath Marine platform, you represent and warrant that you own or have obtained all rights, licenses, consents,
                permissions, power and/or authority, necessary to use and/or upload such content and that such content or the use thereof in the Site
                does not and shall not (a) infringe or violate any intellectual property, proprietary or privacy, data protection or publicity rights
                of any third party; (b) violate any applicable local, state, federal and international laws, regulations and conventions; and/or (c)
                violate any of your or third party's policies and/or terms of service. We invite everyone to report violations together with proof of
                ownership as appropriate. Reported violating content may be removed or disabled.
              </p>
              <p className="termOfServicesText">
                Furthermore, Ath Marine is not responsible for the content, quality or the level of service provided by the Partners. We provide no
                warranty with respect to the spare parts used or the service or any communications between Customers and Partners. We encourage users
                to take advantage of our rating system and common sense in choosing appropriate services and Partners.
              </p>
              <p className="termOfServicesText">
                By offering a service, the Partner undertakes that he/she has sufficient permissions, rights and/or licenses to provide, sell or
                resell the service that is offered on Ath Marine.
              </p>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  Disclaimer of Warranties
                </p>
              </b>
              <p className="termOfServicesText">
                Your
                <span style={{ textTransform: 'lowercase' }}>
                  USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY
                  SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
                  EITHER EXPRESS OR IMPLIED. NEITHER ATH MARINE NOR ANY PERSON ASSOCIATED WITH ATH MARINE MAKES ANY WARRANTY OR REPRESENTATION WITH
                  RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE.
                </span>
              </p>
              <p className="termOfServicesText">
                The
                <span style={{ textTransform: 'lowercase' }}>
                  FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </span>
              </p>
              <b
                className="termOfServicesPrimaryHeading

"
              >
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  Machine Translation
                </p>
              </b>
              <p className="termOfServicesText">
                Certain user-generated content on the Site has been translated for your convenience using translation software. Reasonable efforts
                have been made to provide an accurate translation; however, no automated translation is perfect nor is it intended to replace human
                translators. Such translations are provided as a service to users of the Site, and are provided "as is". No warranty of any kind,
                either expressed or implied, is made as to the accuracy, reliability, or correctness of such translations made from English into any
                other language. Some user-generated content (such as images, videos, Flash, etc.) may not be accurately translated or translated at
                all due to the limitations of the translation software.
              </p>
              <p className="termOfServicesText">
                The official text is the English version of the Site. Any discrepancies or differences created in the translation are not binding and
                have no legal effect for compliance or enforcement purposes. If any questions arise related to the accuracy of the information
                contained in the translated content, please refer to the English version of the content which is the official version.
              </p>
              <b className="termOfServicesPrimaryHeading">
                <p className="termOfServicesText" style={{ marginTop: 20 }}>
                  Limitation on Liability
                </p>
              </b>
              <p className="termOfServicesText">
                No
                <span style={{ textTransform: 'lowercase' }}>
                  EVENT WILL ATH MARINE, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
                  DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY
                  WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH
                  OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                  TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                  SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
                  OTHERWISE, EVEN IF FORESEEABLE.
                </span>
              </p>
              <p className="termOfServicesText">
                The
                <span style={{ textTransform: 'lowercase' }}>
                  FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </span>
              </p>
              <p className="termOfServicesText">
                The term “Affiliate” referred to herein, is an entity that, directly or indirectly, controls, or is under the control of, or is under
                common control with Ath Marine.
              </p>
            </>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
