export default function ViewContainer({ children, className, styles }) {
    const containerStyle = {
        backgroundColor: "var(--grey-tertiary)",
        borderRadius: '8px',
        padding: '20px',
        margin: '20px 0px',
        ...styles
    };
    return (
        <div className={`container ${className}`} style={containerStyle}>
            {children}
        </div>
    );
}
