export default function TertiaryButton({ name, icon, onClick = () => {} }) {
  return (
    <button
      type="button"
      className="tertiary_filled_btn_container"
      onClick={onClick}
    >
      {icon && <span className="tertiary_filled_btn_icon">{icon}</span>}
      <span className="tertiary_filled_btn_text">{name}</span>
    </button>
  );
}
