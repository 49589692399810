import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  format,
  isAfter,
  parse,
  parseISO,
  subDays,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import moment from "moment";

export default class DateUtils {
  static extractDateOnly = (dateString, dateFormat = "dd/MM/yyyy") => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = format(new Date(dateString), dateFormat);
    } catch (e) {
      date = "";
    }
    return date;
  };

  static convertDateToFormat = (date, dateFormat = "DD-MM-yyyy") => {
    if (date == null || date == "") {
      return "";
    }
    try {
      const formattedDate = moment(date).format(dateFormat);
      return formattedDate;
    } catch (e) {
      return "";
    }
  };

  static getStartDateOfMonth = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = startOfMonth(new Date(dateString));
    } catch (e) {
      date = "";
    }
    return date;
  };

  static convertDateDMMMYYYY = (date) => {
    if (date == null || date == "") {
      return "";
    }
    const formattedDate = moment(date).format("D MMM YYYY");
    return formattedDate;
  };

  static getEndDateOfMonth = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = endOfMonth(new Date(dateString));
    } catch (e) {
      date = "";
    }
    return date;
  };

  static extractTimeOnly = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let time = "";
    try {
      time = format(new Date(dateString), "hh:mm a");
    } catch (e) {
      time = "";
    }
    return time;
  };
  static getDateAndTime = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }

    const date = format(new Date(dateString), "dd-MMM-yyyy hh:mm aa");
    return date;
  };
  static parseDateString = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let returnDate;
    try {
      returnDate = parse(dateString, "yyyy-MM-dd", new Date());
    } catch (e) {
      returnDate = "";
    }
    return returnDate;
  };

  static formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  static subtractDaysFromNow = (days) => {
    let date = new Date();
    date = subDays(date, days);
    return date;
  };

  static formatDateyyyyMMdd = (dateString) => {
    if (dateString == null || dateString == "") {
      return "";
    }
    let date = "";
    try {
      date = format(dateString, "yyyy-MM-dd");
    } catch (e) {
      date = "";
    }
    return date;
  };

  static addDaysToNow = (amount) => {
    let date = new Date();
    // date = addMinutes(date, amount);
    date = addDays(date, amount);
    return date;
  };

  static addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  static addHoursToDate(date, hours) {
    var result = new Date(date);
    result = addHours(date, hours);

    return result;
  }
  static addMonthsToDate(date, months) {
    var result = new Date(date);
    result = addMonths(date, months);
    return result;
  }

  static addMinutesToDate(minutes) {
    var result = addMinutes(new Date(), minutes);
    return result;
  }

  static parseStringToDate(dateString) {
    let date;
    try {
      date = parseISO(dateString);
    } catch (e) {
      date = null;
    }
    return date;
  }

  static isDatePastNow = (date) => {
    return isAfter(new Date(), date);
  };

  static convertDateToddMMMyyyy(date) {
    if (date == null) {
      return "";
    }
    let dateString = "";
    try {
      dateString = format(new Date(date), "dd MMM yyyy");
    } catch (e) {
      dateString = "";
    }
    return dateString;
  }

  static convertDateFormat(date, toFormat = "dd/MM/yyyy") {
    if (date == null) {
      return "";
    }
    let dateString = "";
    try {
      dateString = format(new Date(date), toFormat);
    } catch (e) {
      dateString = "";
    }
    return dateString;
  }

  static convertTimeFormat(time) {
    if (time == null) {
      return "";
    }
    let dateString = "";
    try {
      dateString = format(
        parse("2023-04-01 " + time, "yyyy-MM-dd HH:mm", new Date()),
        "hh:mm a"
      );
    } catch (e) {
      dateString = "";
    }
    return dateString;
  }

  static formatDateObject(dateString, toFormat = "dd/MM/yy (EEE) HH:mm") {
    if (!dateString) {
      return null
    }
    const date = new Date(dateString);
    return format(date, toFormat);
  }
}
