import React from 'react';
import RequestorLayoutWrapper from '../RequestorLayout';

export default function RequestorPurchaseOrder() {
  return (
    <RequestorLayoutWrapper>
      <div>Purchase Order</div>
    </RequestorLayoutWrapper>
  );
}
