import ReactPaginate from 'react-paginate';

import { AppConstants } from '../../constants';

export default function Pagination({ totalData, currentPage, onPageChangeHandler }) {
  return (
    <ReactPaginate
      previousLabel={'<<'}
      nextLabel={'>>'}
      breakLabel={'...'}
      forcePage={currentPage}
      pageCount={Math.ceil(totalData / AppConstants.TABLE_PAGE_SIZE)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={1}
      onPageChange={event => {
        onPageChangeHandler(event.selected);
      }}
      containerClassName={'pagination justify-content-center'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      activeClassName={'active'}
    />
  );
}
