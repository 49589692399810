import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_PORTS,
  GET_PORTS_ERROR,
  GET_PORTS_SUCCESS,
} from "../../actionTypes.js";

function* getPorts({ params }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/port/${params?.countryId}`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_PORTS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PORTS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PORTS_ERROR, payload: error });
  }
}

export function* watchGetPorts() {
  yield takeEvery(GET_PORTS, getPorts);
}

function* getPortsSaga() {
  yield all([fork(watchGetPorts)]);
}

export default getPortsSaga;
