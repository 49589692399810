import React, { useState } from "react";

import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordField = ({ ...props }) => {
	const [isPasswordShow, setIsPasswordShow] = useState(false);

	return (
		<div style={{ position: "relative" }}>
			<input
				type={`${isPasswordShow ? "text" : "password"}`}
				className="passwordField pwd"
				autoComplete="off"
				{...props}
			/>
			<span
				className="eyeIcon"
				onClick={() => setIsPasswordShow(!isPasswordShow)}
			>
				{isPasswordShow ? <FaEye /> : <FaEyeSlash />}
			</span>
		</div>
	);
};

export default PasswordField;
