import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_COUNTRIES,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
} from "../../actionTypes.js";

function* getCountries() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/country`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_COUNTRIES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_COUNTRIES_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_COUNTRIES_ERROR, payload: error });
  }
}

export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES, getCountries);
}

function* getCountriesSaga() {
  yield all([fork(watchGetCountries)]);
}

export default getCountriesSaga;
