import Home from "./../pages/Home";
import Login from "./../pages/Login";

import CustomerRequestorDashboard from "../pages/Dashboard/Customer/Requestor/Dashboard";
import {
  CustomerRegistration,
  VendorRegistration,
} from "../pages/Registration";
import { CustomerSignUp, VendorSignUp } from "../pages/Signup";

import { AppRoutes } from "../components/constants";
import {
  CustomerAdminDashboard,
  CustomerApproverDashboard,
  CustomerFinancierDashboard,
  CustomerHeadDashboard,
  CustomerProfile,
} from "../pages/Dashboard/Customer";
import {
  VendorAdminDashboard,
  VendorApproverDashboard,
  VendorBidderDashboard,
  VendorEngineerDashboard,
  VendorFinancierDashboard,
  VendorHeadDashboard,
  VendorProfile,
} from "../pages/Dashboard/Vendor";
import {
  About,
  Contact,
  FAQS,
  OurSolutions,
  PrivacyPolicy,
  RulesGuidelines,
  TermsOfService,
} from "./../pages/Static/index";

import {
  RequestorActiveRequests,
  RequestorAddNewRequest,
  RequestorApproverAddCostDiscount,
  RequestorArchive,
  RequestorCompletedJobs,
  RequestorInvoiceRaised,
  RequestorLiveStatus,
  RequestorOpenRequests,
  RequestorPurchaseOrder,
  RequestorRequestedWithNoResponse,
  RequestorResponseToRequests,
  RequestorWorkInProgress,
} from "../pages/Dashboard/Customer/Requestor";
import {
  BidderActiveRequests,
  BidderOpenRequests,
  BidsInProgress,
  CompletedInvoice,
  History,
  InterestedRequests,
  LiveStatus,
  NewRequests,
  PurchaseOrderReceived,
  RaisedBids,
  RaisedCostDiscount,
  WorkInProgress,
} from "../pages/Dashboard/Vendor/Bidder";
import ServiceRequestDetails from "../pages/Dashboard/Vendor/Bidder/NewRequests/ServiceRequestDetails";
import InterestedServiceRequestDetails from "../pages/Dashboard/Vendor/Bidder/InterestedRequests/InterestedServiceRequestDetails";
import BreakUps from "../pages/Dashboard/Vendor/Bidder/InterestedRequests/BreakUps";
import BidsProposal from "../pages/Dashboard/Vendor/Bidder/InterestedRequests/BidsProposal";

const publicRoutes = [
  // ***************** PUBLIC *****************
  // STATIC PAGES
  { path: AppRoutes.APP_HOME, component: Home },
  { path: AppRoutes.FAQS, component: FAQS },
  { path: AppRoutes.HOME, component: Home },
  { path: AppRoutes.LOGIN, component: Login },
  { path: AppRoutes.ABOUT_US, component: About },
  { path: AppRoutes.CONTACT_US, component: Contact },
  { path: AppRoutes.OUR_SOLUTIONS, component: OurSolutions },
  { path: AppRoutes.PRIVACY_POLICY, component: PrivacyPolicy },
  { path: AppRoutes.TERM_SERVICES, component: TermsOfService },
  { path: AppRoutes.RULES_GUIDELINES, component: RulesGuidelines },

  // ***************** SIGNUP *****************
  { path: AppRoutes.VENDOR_SIGNUP, component: VendorSignUp },
  { path: AppRoutes.CUSTOMER_SIGNUP, component: CustomerSignUp },
];

const authProtectedRoutes = [
  // ***************** REGISTRATION *****************
  { path: "/vendor/registration", component: VendorRegistration },
  { path: "/customer/registration", component: CustomerRegistration },

  { path: "/customer/profile", component: CustomerProfile },
  { path: "/vendor/profile", component: VendorProfile },

  // VENDOR HEAD DASHBOARD
  { path: "/vendor/dashboard/head", component: VendorHeadDashboard },

  // VENDOR ADMIN DASHBOARDS
  { path: "/vendor/dashboard/admin", component: VendorAdminDashboard },

  // VENDOR ENGINEER DASHBOARDS
  { path: "/vendor/dashboard/engineer", component: VendorEngineerDashboard },

  // VENDOR APPROVER DASHBOARDS
  { path: "/vendor/dashboard/approver", component: VendorApproverDashboard },

  // VENDOR FINANCIER DASHBOARDS
  { path: "/vendor/dashboard/financier", component: VendorFinancierDashboard },

  // ***************** CUSTOMER DASHBOARDS *****************
  // CUSTOMER HEAD DASHBOARDS
  { path: "/customer/dashboard/head", component: CustomerHeadDashboard },

  // CUSTOMER ADMIN DASHBOARDS
  { path: "/customer/dashboard/admin", component: CustomerAdminDashboard },

  // CUSTOMER APPROVER DASHBOARDS
  {
    path: "/customer/dashboard/approver",
    component: CustomerApproverDashboard,
  },

  // CUSTOMER FINANCIER DASHBOARDS
  {
    path: "/customer/dashboard/financier",
    component: CustomerFinancierDashboard,
  },

  // ***************** VENDOR DASHBOARDS *****************
  // BIDDER
  { path: AppRoutes.VENDOR_BIDDER_DASHBOARD, component: VendorBidderDashboard },
  {
    path: AppRoutes.VENDOR_BIDDER_ACTIVE_REQUESTS,
    component: BidderActiveRequests,
  },
  // { path: AppRoutes.VENDOR_BIDDER_NEW_REQUESTS, component: BidderOpenRequests },
  { path: AppRoutes.VENDOR_BIDDER_LIVE_STATUS, component: LiveStatus },
  {
    path: AppRoutes.VENDOR_BIDDER_SERVICE_REQUEST_DETAILS,
    component: ServiceRequestDetails,
  },
  { path: AppRoutes.VENDOR_BIDDER_NEW_REQUESTS, component: NewRequests },
  {
    path: AppRoutes.VENDOR_BIDDER_INTERESTED_REQUESTS,
    component: InterestedRequests,
  },
  {
    path: AppRoutes.VENDOR_BIDDER_INTERESTED_SERVICE_REQUEST_DETAILS,
    component: InterestedServiceRequestDetails,
  },
  {
    path: AppRoutes.VENDOR_BIDDER_INTERESTED_BREAK_UPS,
    component: BreakUps,
  },
  {
    path: AppRoutes.VENDOR_BIDDER_INTERESTED_BIDS_PROPOSAL,
    component: BidsProposal,
  },
  { path: AppRoutes.VENDOR_BIDDER_BIDS_IN_PROGRESS, component: BidsInProgress },
  { path: AppRoutes.VENDOR_BIDDER_RAISED_BIDS, component: RaisedBids },
  {
    path: AppRoutes.VENDOR_BIDDER_PURCHASE_ORDER_RECEIVED,
    component: PurchaseOrderReceived,
  },
  { path: AppRoutes.VENDOR_BIDDER_WORK_IN_PROGRESS, component: WorkInProgress },
  {
    path: AppRoutes.VENDOR_BIDDER_RAISED_COST_DISCOUNT,
    component: RaisedCostDiscount,
  },
  {
    path: AppRoutes.VENDOR_BIDDER_COMPLETED_INVOICE,
    component: CompletedInvoice,
  },
  { path: AppRoutes.VENDOR_BIDDER_HISTORY, component: History },

  // ***************** CUSTOMER DASHBOARDS *****************
  // REQUESTOR
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_DASHBOARD,
    component: CustomerRequestorDashboard,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_ACTIVE_REQUESTS,
    component: RequestorActiveRequests,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_OPEN_REQUESTS,
    component: RequestorOpenRequests,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_LIVE_STATUS,
    component: RequestorLiveStatus,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_RESPONSE_TO_REQUESTS,
    component: RequestorResponseToRequests,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_PURCHASE_ORDERS,
    component: RequestorPurchaseOrder,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_WORK_IN_PROGRESS,
    component: RequestorWorkInProgress,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_APPROVER_ADD_COST_DISCOUNT,
    component: RequestorApproverAddCostDiscount,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_COMPLETED_JOBS,
    component: RequestorCompletedJobs,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_INVOICE_RAISEDS,
    component: RequestorInvoiceRaised,
  },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_REQUESTED_WITH_NO_RESPONSE,
    component: RequestorRequestedWithNoResponse,
  },
  { path: AppRoutes.CUSTOMER_REQUESTOR_ARCHIVE, component: RequestorArchive },
  {
    path: AppRoutes.CUSTOMER_REQUESTOR_ADD_NEW_REQUEST,
    component: RequestorAddNewRequest,
  },
];

export { authProtectedRoutes, publicRoutes };
