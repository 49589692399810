import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../components/constants";

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${AppRoutes.LOGIN}`);
  }, []);

  return <div>Home</div>;
}
