import {
  MARK_INTERESTED,
  MARK_INTERESTED_SUCCESS,
  MARK_INTERESTED_ERROR,
  MARK_INTERESTED_EMPTY,
} from "../../../../actionTypes";

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const MarkInterested = (state = initialState, action) => {
  switch (action.type) {
    case MARK_INTERESTED:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        message: null,
      };

    case MARK_INTERESTED_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };

    case MARK_INTERESTED_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };

    case MARK_INTERESTED_EMPTY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default MarkInterested;
