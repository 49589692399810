export class PersonRoleIds {
  static BIDDER = 2;
  static REQUESTOR = 7;
}

export class ServiceRequestStatuses {
  static ALL = 0;
  static OPEN = 1;
  static RECEIVED_BID = 2;
  static RAISED_PO = 3;
  static APPROVED_PO = 4;
  static REJECTED_PO = 5;
  static WORK_COMPLETED = 6;
  static INVOICE_RAISED = 7;
  static WITHDRAW = 8;
  static CLOSED = 9;
  static DISCARD_NO_BID_RECEIVED = 9;
}

export class BidStatuses {
  static ALL = 0;
  static NEW = 1;
  static INTERESTED = 2;
  static PENDING_APPROVAL = 3;
  static IN_PROGRESS = 4;
  static REJECTED = 5;
  static FARE_VALUE_CALCULATED = 6;
  static RAISED = 6;
  static RECEIVED_PO = 7;
  static WORK_IN_PROGRESS = 8;
  static WORK_COMPLETED = 9;
  static INVOICE_RAISED = 10;
  static WITHDRAW = 11;
  static COMPLETED = 12;
  static DISCARDED = 13;
}
