import Select from "react-select";

export default function SelectField({ className = ``, ...props }) {
  return (
    <Select
      className={`${props.flag ? "selectFieldPlane" : "selectField"
        } ${className}`}
      styles={{
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          fontSize: "14px",
          padding: "8px 15px",

          ":hover": {
            cursor: "pointer",
          },
        }),

        singleValue: (provided, state) => ({
          ...provided,
          color: "black",
          fontSize: "16px",
        }),

        placeholder: (styles) => ({
          ...styles,
          color: "black",
        }),

        control: (styles, state) => ({
          ...styles,
          border: state.isFocused ? 0 : 0,
          boxShadow: state.isFocused ? 0 : 0,
          backgroundColor: state.isDisabled ? "var(--disable)" : "white",
          "&:hover": {
            border: state.isFocused ? 0 : 0,
          },
        }),

        menu: (styles) => ({
          ...styles,
          borderRadius: 0,
          width: "100%",
          border: "none",
        }),

        dropdownIndicator: (styles) => ({ ...styles, color: "black" }),
        indicatorSeparator: (styles) => ({ ...styles, display: "none" }),

        // multiValue: (styles) => ({ ...styles, background: 'orange' }),
        // multiValueRemove: (styles) => ({ ...styles, color: 'green' }),
        // clearIndicator: (styles) => ({ ...styles, color: 'pink' }),
        // multiValueLabel: (styles) => ({
        //   ...styles,
        //   border: '1px solid red',
        // }),
      }}
      {...props}
    />
  );
}
