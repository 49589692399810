import React from 'react';
import DashboardLayoutWrapper from '../BidderLayout';

export default function RaisedCostDiscount() {
  return (
    <DashboardLayoutWrapper>
      <div>
        Raised Cost Discount
      </div>
    </DashboardLayoutWrapper>
  );
}
