import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_VESSEL_FLAGS,
  GET_VESSEL_FLAGS_ERROR,
  GET_VESSEL_FLAGS_SUCCESS,
} from "../../actionTypes.js";

function* getVesselFlags() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/vessel-flag`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_VESSEL_FLAGS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VESSEL_FLAGS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VESSEL_FLAGS_ERROR, payload: error });
  }
}

export function* watchGetVesselFlags() {
  yield takeEvery(GET_VESSEL_FLAGS, getVesselFlags);
}

function* getVesselFlagsSaga() {
  yield all([fork(watchGetVesselFlags)]);
}

export default getVesselFlagsSaga;
