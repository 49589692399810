import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import HttpClient from "../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  UPDATE_SERVICE_REQUEST_STATUS,
  UPDATE_SERVICE_REQUEST_STATUS_SUCCESS,
  UPDATE_SERVICE_REQUEST_STATUS_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* updateServiceRequestStatus({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/service/v1/request/update-status`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: UPDATE_SERVICE_REQUEST_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: UPDATE_SERVICE_REQUEST_STATUS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_SERVICE_REQUEST_STATUS_ERROR, payload: error });
  }
}

export function* watchUpdateServiceRequestStatus() {
  yield takeEvery(UPDATE_SERVICE_REQUEST_STATUS, updateServiceRequestStatus);
}

function* updateServiceRequestStatusSaga() {
  yield all([fork(watchUpdateServiceRequestStatus)]);
}

export default updateServiceRequestStatusSaga;
