import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { baseUrl, headers } from "../../../config/index.js";
import {
  VENDOR_LOGIN,
  VENDOR_LOGIN_SUCCESS,
  VENDOR_LOGIN_ERROR,
} from "../actionTypes.js";
import HttpClient from "../../../config/http-client.js";
import ToastUtils from "../../../utils/toast/toast-utils.js";

function* vendorLogin({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/vendor/v1/login`,
      method: "POST",
      headers: {
        ...headers,
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: VENDOR_LOGIN_SUCCESS,
        payload: response,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: VENDOR_LOGIN_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_LOGIN_ERROR, payload: error });
  }
}

export function* watchVendorLogin() {
  yield takeEvery(VENDOR_LOGIN, vendorLogin);
}

function* LoginSaga() {
  yield all([fork(watchVendorLogin)]);
}

export default LoginSaga;
