import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import HttpClient from './../../../../../config/http-client.js';
import { baseUrl, headers, authToken } from '../../../../../config/index.js';
import { CREATE_ENGINEER, CREATE_ENGINEER_SUCCESS, CREATE_ENGINEER_ERROR } from '../../../actionTypes.js';

function* createVendorEngineer({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/admin/bookings`,
      method: 'POST',
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_ENGINEER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_ENGINEER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_ENGINEER_ERROR, payload: error });
  }
}

export function* watchCreateVendorEngineer() {
  yield takeEvery(CREATE_ENGINEER, createVendorEngineer);
}

function* createVendorEngineerSaga() {
  yield all([fork(watchCreateVendorEngineer)]);
}

export default createVendorEngineerSaga;
