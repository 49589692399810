import React from 'react';
import DashboardLayoutWrapper from '../BidderLayout';

export default function History() {
  return (
    <DashboardLayoutWrapper>
      <div>
        History
      </div>
    </DashboardLayoutWrapper>
  );
}
