import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import HttpClient from "../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  UPDATE_BID_STATUS,
  UPDATE_BID_STATUS_SUCCESS,
  UPDATE_BID_STATUS_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* updateBidStatus({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/bid/v1/update-status`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: UPDATE_BID_STATUS_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: UPDATE_BID_STATUS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_BID_STATUS_ERROR, payload: error });
  }
}

export function* watchUpdateBidStatus() {
  yield takeEvery(UPDATE_BID_STATUS, updateBidStatus);
}

function* updateBidStatusSaga() {
  yield all([fork(watchUpdateBidStatus)]);
}

export default updateBidStatusSaga;
