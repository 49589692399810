export default function TextAreaField({ value = "", maxLength = 100, onChange, ...props }) {
  const handleInputChange = (e) => {
    if (e.target.value.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <>
      <textarea
        className="textareaField"
        value={value}
        onChange={handleInputChange}
        autoComplete="off"
        {...props}
      />
      <span className="text-area-count">
        {value?.length}/{maxLength}
      </span>
    </>
  );
}
