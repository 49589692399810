import { ModalBody } from "reactstrap";
import { SecondaryButton, PrimaryButton, ModalLayout } from "../../common"

function ModalConfirmation({ onProceed, onCancel, msg, size = "lg" }) {
    return (
        <ModalLayout isOpen={true} size={size} centered>
            <ModalBody>
                <div className="service_request_confirmation_modal_body_container">
                    <h5 className="service_request_heading">
                        {msg}
                    </h5>

                    <div className="service_request_btn_container">
                        <SecondaryButton name="Cancel" onClick={onCancel} />
                        <PrimaryButton name="Proceed" onClick={onProceed} />
                    </div>
                </div>
            </ModalBody>
        </ModalLayout>
    );
}

export default ModalConfirmation;
