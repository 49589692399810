import { useNavigate } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

import { useDispatch } from "react-redux";
import Notification_Icon from "../../../assets/images/icon-notifications.svg";
import Man_Profile_Icon from "../../../assets/images/user-image-icon.png";
import { VENDOR_LOGIN_EMPTY } from "../../../store/apis/actionTypes";
import { ProjectConfig } from "../../config/project-config";
import { AppRoutes } from "../../constants";
import { useProfile } from "../../Hooks/UserHooks";
import AthMarineLogo from "./../../../assets/images/AthMarineLogo.png";
import { PrimaryButton, SecondaryButton } from "./../../../components/common";
import { useEffect, useState } from "react";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userProfile } = useProfile();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    if (userProfile !== null) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [userProfile]);

  return (
    <header className="section_heading">
      <div className="section_heading_container">
        <div
          className="logo-container"
          onClick={() => navigate(AppRoutes.LOGIN)}
        >
          <img src={AthMarineLogo} className="logo" alt="logo" />
        </div>

        {!isUserLoggedIn && (
          <div className="before-login-option-container">
            <div className="navigation-links">
              <ul>
                <li>Home</li>
                <li>About Us</li>
                <li>Our Solutions</li>
                <li>FAQs</li>
                <li>Contact Us</li>
              </ul>
            </div>

            <div className="navigation-buttons">
              <PrimaryButton
                name="Vendor Registration"
                onClick={() => navigate(AppRoutes.VENDOR_SIGNUP)}
              />

              <PrimaryButton
                name="Customer Registration"
                onClick={() => navigate(AppRoutes.CUSTOMER_SIGNUP)}
              />

              <SecondaryButton
                name="Login"
                onClick={() => navigate(AppRoutes.LOGIN)}
              />
            </div>
          </div>
        )}

        {isUserLoggedIn && (
          <div className="after-login-option-container">
            <span className="icon-container">
              <img
                src={Notification_Icon}
                width={"26px"}
                height={"26px"}
                alt="Notification_Icon"
              />
            </span>

            <div className="my-dropdown">
              <Dropdown
                className=""
                onSelect={(eventKey) => {
                  console.log(`Selected option: ${eventKey}`);
                }}
              >
                <Dropdown.Toggle className="profile-dropdown">
                  <span className="profile-icon-container">
                    <img src={Man_Profile_Icon} alt="Man_Profile_Icon" />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {ProjectConfig?.profileDropdownMenu?.map((menu, index) => (
                    <div key={index}>
                      <Dropdown.Item
                        onClick={() => {
                          if (menu?.isLogout) {
                            localStorage.clear();
                            dispatch({
                              type: VENDOR_LOGIN_EMPTY,
                            });

                            setTimeout(() => {
                              navigate(AppRoutes.LOGIN);
                            }, 100);
                          } else {
                            navigate(menu?.path);
                          }
                        }}
                      >
                        <div className="profile-dropdown-menu-options ">
                          <div className="custom-icon-container">
                            <span className="custom-icon">{menu?.icon}</span>
                          </div>
                          <div className="">{menu?.name}</div>
                        </div>
                      </Dropdown.Item>
                    </div>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
