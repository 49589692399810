export default function SecondaryButton({ name, icon, onClick = () => {} }) {
  return (
    <button
      type="button"
      className={`secondary_bordered_btn_container`}
      onClick={onClick}
    >
      {icon && <span className="secondary_bordered_btn_icon">{icon}</span>}
      <span className="secondary_bordered_btn_text">{name}</span>
    </button>
  );
}
