import {
  UPDATE_SERVICE_REQUEST_STATUS,
  UPDATE_SERVICE_REQUEST_STATUS_SUCCESS,
  UPDATE_SERVICE_REQUEST_STATUS_ERROR,
  UPDATE_SERVICE_REQUEST_STATUS_EMPTY,
} from '../../../../actionTypes';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const UpdateServiceRequestStatus = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SERVICE_REQUEST_STATUS:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        message: null,
      };

    case UPDATE_SERVICE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };

    case UPDATE_SERVICE_REQUEST_STATUS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };

    case UPDATE_SERVICE_REQUEST_STATUS_EMPTY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default UpdateServiceRequestStatus;
