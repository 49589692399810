import { useEffect } from "react";

const Drawer = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isOpen && event.target.className === 'drawer-overlay') {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen, onClose]);

    return (
        <div className={`drawer-overlay ${isOpen ? 'open' : ''}`}>
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <button className="close-btn" onClick={onClose}>X</button>
                {children}
            </div>
        </div>
    );
};
export default Drawer


