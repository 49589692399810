export default function Container({ children, className }) {
  const containerStyle = {
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '20px',
    margin: '20px 0px'
  };
  return (
    <div className={`container shadow  ${className}`} style={containerStyle}>
      {children}
    </div>
  );
}
