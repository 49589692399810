import { useNavigate } from "react-router-dom";

export default function Card({ label, number, icon, path, type }) {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="cards" onClick={() => handleNavigation(path)}>
      <p className="cardNumber">{`${number ?? 0}`}</p>
      <p className="cardText">{label}</p>
    </div>
  );
}
