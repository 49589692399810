import { Col, Row } from "react-bootstrap"
import ViewContainer from "../../../../../components/common/ViewContainer"
import ViewLabel from "../../../../../components/common/ViewLabel"
import DashboardLayoutWrapper from "../BidderLayout"
import HorizontalLine from "../../../../../components/common/HorizontalLine"
import TextValue from "../../../../../components/common/TextValue"
import SelectField from "../../../../../components/common/FormControls/SelectField"
import { PrimaryButton } from "../../../../../components/common"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../../../components/constants"

const BreakUps = () => {
    const navigate = useNavigate()
    return <DashboardLayoutWrapper>
        <div className="card request-card p-3">
            <div className="row ">
                <div className="col-md-4">
                    <p className="mb-1 fw-bold">Request ID:</p>
                    <p className="mb-0">123400000–0122–001</p>
                </div>

                <div className="col-md-4">
                    <p className="mb-1 fw-bold">Ship Name:</p>
                    <p className="mb-0">Lorem Ipsum</p>
                </div>

                <div className="col-md-4">
                    <p className="mb-1 fw-bold">Category:</p>
                    <p className="mb-0">Bridge</p>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
            <ViewLabel>Currency: INR</ViewLabel>
        </div>

        <div className="request-card my-3" >
            <div className="breakup-header">
                <Row >
                    <Col lg="4" >
                        <p className="mb-1 fw-bold">Description</p>
                    </Col>
                    <Col lg="4" className="text-center">
                        <p className="mb-1 fw-bold">Proposed Amount</p>
                    </Col>
                    <Col lg="4" className="text-center">
                        <p className="mb-1 fw-bold">Received Amount</p>
                    </Col>
                </Row>
            </div>


            <Row className="mt-3">
                <Col className="mb-1 fw-bold text-center">Working (A)</Col>
            </Row>
            <HorizontalLine />
            <div className="breadup-content">
                <Row>
                    <Col lg="4" >
                        <TextValue>Normal Days Normal Hours</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Normal Days OT 1</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Normal Days OT 2</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Weekend Normal Hours</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Weekend OT 1</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Weekend OT 2</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Lump sum</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Others</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <ViewLabel>
                            Total (A)
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                </Row>
            </div>
        </div>
        <ViewContainer>
            <Row>
                <Col className="mb-1 fw-bold text-center">Working (B)</Col>
            </Row>
            <HorizontalLine />
            <div className="breadup-content">
                <Row>
                    <Col lg="4" >
                        <TextValue>Air Fare</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Transportation 1</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Transportation 2</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Travel Time Normal Hours</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Travel Time OT1</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Travel Time OT2</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Lump sum</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Others</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <ViewLabel>
                            Total (B)
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                </Row>
            </div>
        </ViewContainer>
        <ViewContainer>
            <Row>
                <Col className="mb-1 fw-bold text-center">Working (C)</Col>
            </Row>
            <HorizontalLine />
            <div className="breadup-content">
                <Row>
                    <Col lg="4" >
                        <TextValue>Mandatory Spares</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Port Charges</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Covid related Charges</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Shipyard Charges</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Accommodation</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <TextValue>Service Admin fee</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Lump sum</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>Others</TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <TextValue>
                            Miscellaneous
                        </TextValue>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <TextValue>450</TextValue>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <ViewLabel>
                            Total (C)
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>
                            4,556
                        </ViewLabel>
                    </Col>
                </Row>


            </div>
        </ViewContainer>
        <ViewContainer>
            <div className="breadup-content">
                <Row>
                    <Col lg="4" >
                        <ViewLabel>Local Tax</ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <input className="proposed" />
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>2400</ViewLabel>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" >
                        <ViewLabel>Total (A+B+C)</ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>2,464</ViewLabel>
                    </Col>
                    <Col lg="4" className="text-center">
                        <ViewLabel>2,464</ViewLabel>

                    </Col>
                </Row>
            </div>

        </ViewContainer>
        <ViewContainer>
            <div className="breadup-content">
                <Row>
                    <Col lg={4}>
                        <TextValue>Lead engineer</TextValue>
                    </Col>
                    <Col lg={8} className="lead-engineer" >
                        <SelectField className="engineer" />
                        <SelectField className="engineer" />
                        <SelectField className="engineer" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <TextValue>Expected hours needed:</TextValue>
                    </Col>
                    <Col lg={3} className="lead-engineer" >
                        <input className="proposed" />
                        <input className="proposed" />

                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <TextValue>No Engineer(s)</TextValue>
                    </Col>
                    <Col lg={3} className="" >
                        <input className="proposed" />
                    </Col>
                    <Col>454
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <TextValue>Warranty on service (months)</TextValue>
                    </Col>
                    <Col lg={3} className="" >
                        <input className="proposed" />
                    </Col>
                    <Col>454
                    </Col>
                </Row>
            </div>
        </ViewContainer>
        <div className="d-flex justify-content-end mt-2">
            <PrimaryButton name="Proceed" onClick={() => navigate(AppRoutes.VENDOR_BIDDER_INTERESTED_BIDS_PROPOSAL)} />
        </div>
    </DashboardLayoutWrapper >

}
export default BreakUps