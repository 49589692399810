import { AiOutlineUser } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { IoCallOutline } from "react-icons/io5";
import { AppRoutes } from "../constants";

export const ProjectConfig = {
  profileDropdownMenu: [
    {
      path: AppRoutes.CUSTOMER_PROFILE,
      name: "Profile",
      icon: <AiOutlineUser />,
    },
    {
      path: AppRoutes.CONTACT_US,
      name: "Support",
      icon: <IoCallOutline />,
    },
    {
      name: "Logout",
      icon: <BiLogOutCircle />,
      isLogout: true,
    },
  ],
};
