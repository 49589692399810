import React from "react";
import RequestorLayoutWrapper from "../RequestorLayout";

export default function RequestorResponseToRequests() {
  return (
    <RequestorLayoutWrapper>
      <div>Work in Progress</div>
    </RequestorLayoutWrapper>
  );
}
