import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "reactstrap";

import {
  DashboardLoader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../components/common";
import Container from "../../../../../components/common/Cantainer";
import DateField from "../../../../../components/common/FormControls/DateField";
import Error from "../../../../../components/common/FormControls/Error";
import FileInput from "../../../../../components/common/FormControls/FileInput";
import Label from "../../../../../components/common/FormControls/Label";
import TextAreaField from "../../../../../components/common/FormControls/Label/TextAreaField";
import SelectField from "../../../../../components/common/FormControls/SelectField";
import TextField from "../../../../../components/common/FormControls/TextField";
import Heading from "../../../../../components/common/Heading";
import HorizontalLine from "../../../../../components/common/HorizontalLine";
import { AppRoutes } from "../../../../../components/constants";
import {
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_REQUEST_EMPTY,
  DELETE_FILE,
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_CURRENCIES,
  GET_EQUIPMENTS,
  GET_ISSUE_TYPES,
  GET_MAKES,
  GET_PORT_LOCATIONS,
  GET_PORTS,
  GET_VESSEL_FLAGS,
  GET_VESSEL_TYPES,
  UPLOAD_FILE,
  UPLOAD_FILE_EMPTY,
} from "../../../../../store/apis/actionTypes";
import UtilityFunc from "../../../../../utils/utility-func";
import RequestorLayoutWrapper from "../RequestorLayout";
import ConfirmationModal from "./ConfirmationModal";
import RequestPlacedSuccessfulModal from "./RequestPlacedSuccessfulModal";

const createServiceRequestInitialState = {
  vesselDetail: {
    name: "",
    vesselTypeId: null,
    imoNumber: "",
    vesselFlagId: null,
  },
  serviceRequestAt: {
    countryId: null,
    portId: null,
    locationId: null,
    eta: "",
    etd: "",
    serviceRequestOn: "",
    bidSendToEmailAddress: [],
    bidCurrencyId: null,
  },
  serviceRequestDetail: {
    equipmentCategoryId: null,
    equipmentName: "",
    equipmentManufacturer: "",
    model: "",
    issueType: "",
  },
  serviceDescription: "",
  files: [],
  bidTime: 72,
};

export default function RequestorAddNewRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState({ ...createServiceRequestInitialState });

  const [isValidationPass, setIsValidationPass] = useState(false);
  const [isReviewState, setIsReviewState] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isRequestCreatedSuccessful, setIsRequestCreatedSuccessful] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedValue, setSelectedValue] = useState("72 Hours");
  const [subOptions, setSubOptions] = useState(
    Array.from({ length: 72 }, (_, i) => `${72 - i}`)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const {
    countries,
    countriesLoading,
    ports,
    portsLoading,
    currencies,
    currenciesLoading,
    categories,
    categoriesLoading,
    vesselTypes,
    vesselTypesLoading,
    vesselFlags,
    vesselFlagsLoading,
    issueTypes,
    issueTypesLoading,
    fileUpload,
    fileUploadLoading,
    portLocations,
    portLocationsLoading,
    serviceRequest,
    serviceRequestLoading,
    equipments,
    equipmentsLoading,
    manufacturers,
    manufacturersLoading,
  } = useSelector((state) => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
    ports: state?.Ports?.data,
    portsLoading: state?.Ports?.loading,
    currencies: state?.Currencies?.data,
    currenciesLoading: state?.Currencies?.loading,
    categories: state?.Categories?.data,
    categoriesLoading: state?.Categories?.loading,
    vesselTypes: state?.VesselTypes?.data,
    vesselTypesLoading: state?.VesselTypes?.loading,
    vesselFlags: state?.VesselFlags?.data,
    vesselFlagsLoading: state?.VesselFlags?.loading,
    issueTypes: state?.IssueTypes?.data,
    issueTypesLoading: state?.IssueTypes?.loading,
    fileUpload: state?.fileUpload?.data,
    fileUploadLoading: state?.fileUpload?.loading,
    portLocations: state?.PortLocations?.data,
    portLocationsLoading: state?.PortLocations?.loading,
    serviceRequest: state?.CreateServiceRequest?.data,
    serviceRequestLoading: state?.CreateServiceRequest?.loading,
    equipments: state?.Equipments?.data,
    equipmentsLoading: state?.Equipments?.loading,
    manufacturers: state?.Makes?.data,
    manufacturersLoading: state?.Makes?.loading,
  }));

  useEffect(() => {
    return () => {
      dispatch({
        type: UPLOAD_FILE_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (serviceRequest !== null) {
      dispatch({
        type: CREATE_SERVICE_REQUEST_EMPTY,
      });

      setIsRequestCreatedSuccessful(true);

      setTimeout(() => {
        setIsRequestCreatedSuccessful(false);
        navigate(AppRoutes?.CUSTOMER_REQUESTOR_ACTIVE_REQUESTS);
      }, 3000);
    }
  }, [serviceRequest]);

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRIES,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_CATEGORIES,
      });
    }

    if (vesselTypes === null) {
      dispatch({
        type: GET_VESSEL_TYPES,
      });
    }

    if (issueTypes === null) {
      dispatch({
        type: GET_ISSUE_TYPES,
      });
    }

    if (vesselFlags === null) {
      dispatch({
        type: GET_VESSEL_FLAGS,
      });
    }

    if (portLocations === null) {
      dispatch({
        type: GET_PORT_LOCATIONS,
      });
    }

    if (manufacturers === null) {
      dispatch({
        type: GET_MAKES,
      });
    }
  }, []);

  const onSelectHandler = (data, label) => {
    let updatedState = { ...state };

    switch (label) {
      case `country`:
        updatedState = {
          ...state,
          serviceRequestAt: {
            ...state?.serviceRequestAt,
            countryId: data.value,
            portId: null,
            locationId: null,
          },
        };
        dispatch({
          type: GET_PORTS,
          params: {
            countryId: data?.value,
          },
        });
        dispatch({
          type: GET_CURRENCIES,
          params: {
            countryId: data?.value,
          },
        });
        break;

      case `portId`:
        updatedState = {
          ...state,
          serviceRequestAt: {
            ...state?.serviceRequestAt,
            portId: data.value,
          },
        };
        break;

      case `location`:
        updatedState = {
          ...state,
          serviceRequestAt: {
            ...state?.serviceRequestAt,
            locationId: data?.value,
          },
        };
        break;

      case `vesselTypeId`:
        updatedState = {
          ...state,
          vesselDetail: {
            ...state?.vesselDetail,
            vesselTypeId: data?.value,
          },
        };
        break;

      case `vesselFlagId`:
        updatedState = {
          ...state,
          vesselDetail: {
            ...state?.vesselDetail,
            vesselFlagId: data?.value,
          },
        };
        break;

      case `equipmentCategoryId`:
        updatedState = {
          ...state,
          serviceRequestDetail: {
            ...state?.serviceRequestDetail,
            equipmentCategoryId: data?.value,
            equipmentName: "",
            equipmentManufacturer: "",
          },
        };

        dispatch({
          type: GET_EQUIPMENTS,
          payload: {
            data: {
              queryData: data?.value,
            },
          },
        });
        break;

      case `equipmentName`:
        updatedState = {
          ...state,
          serviceRequestDetail: {
            ...state?.serviceRequestDetail,
            equipmentName: data?.value,
            equipmentManufacturer: "",
          },
        };
        break;

      case `equipmentManufacturer`:
        updatedState = {
          ...state,
          serviceRequestDetail: {
            ...state?.serviceRequestDetail,
            equipmentManufacturer: data?.value,
          },
        };
        break;

      case `issueType`:
        updatedState = {
          ...state,
          serviceRequestDetail: {
            ...state?.serviceRequestDetail,
            issueType: data?.value,
          },
        };
        break;

      case `bidTime`:
        updatedState = {
          ...state,
          bidTime: data?.target?.value,
        };
        break;

      default:
      // Nothing to do
    }

    setState(updatedState);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedState = { ...state };

    if (name === "vesselName") {
      if (UtilityFunc.isValidAplha(value)) {
        updatedState = {
          ...state,
          vesselDetail: {
            ...state?.vesselDetail,
            name: value,
          },
        };
      }
    } else if (name === "imoNumber") {
      if (UtilityFunc.isANumber(value) || value === "")
        updatedState = {
          ...state,
          vesselDetail: {
            ...state?.vesselDetail,
            imoNumber: value,
          },
        };
    } else if (name === "model") {
      updatedState = {
        ...state,
        serviceRequestDetail: {
          ...state?.serviceRequestDetail,
          model: value,
        },
      };
    } else if (name === "serviceDescription") {
      updatedState = {
        ...state,
        serviceDescription: value,
      };
    }

    setSelectedValue(value);

    let options = [];
    if (value === "72-60 Hrs") {
      updatedState = {
        ...state,
        bidTime: 72,
      };
      options = Array.from({ length: 13 }, (_, i) => `${72 - i}`);
    } else if (value === "59-30 Hrs") {
      updatedState = {
        ...state,
        bidTime: 59,
      };
      options = Array.from({ length: 30 }, (_, i) => `${59 - i}`);
    } else if (value === "29-01 Hrs") {
      options = Array.from({ length: 29 }, (_, i) => `${29 - i}`);
    }
    setSubOptions(options);

    setState(updatedState);
  };

  const handleUpdate = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileData = new FormData();
      fileData.append("file", file);
      fileData.append("type", "sr");
      dispatch({
        type: UPLOAD_FILE,
        payload: fileData,
      });
    }
  };

  const handleSubmit = () => {
    console.log("handle submit ", state, state?.bidTime);
    if (state?.bidTime > 59) {
      dispatch({
        type: CREATE_SERVICE_REQUEST,
        payload: {
          data: {
            ...state,
            files: [...(fileUpload ?? [])],

            serviceRequestAt: {
              ...state?.serviceRequestAt,
              eta: state?.serviceRequestAt?.eta?.[0],
              etd: state?.serviceRequestAt?.etd?.[0],
              serviceRequestOn: state?.serviceRequestAt?.serviceRequestOn?.[0],
            },
          },
        },
      });
    } else {
      setIsShowConfirmationModal(true);
    }
  };

  const checkValidations = () => {
    setIsValidationPass(false);
    setIsReviewState(true);
    if (
      state?.vesselDetail?.name &&
      state?.vesselDetail?.vesselTypeId &&
      state?.serviceRequestAt?.countryId &&
      state?.serviceRequestAt?.portId &&
      state?.serviceRequestAt?.locationId &&
      state?.serviceRequestAt?.eta &&
      state?.serviceRequestAt?.etd &&
      state?.serviceRequestAt?.serviceRequestOn &&
      state?.serviceRequestDetail?.equipmentCategoryId &&
      state?.serviceRequestDetail?.equipmentName &&
      state?.serviceRequestDetail?.equipmentManufacturer &&
      state?.serviceRequestDetail?.issueType
    ) {
      setIsValidationPass(false);
      setIsReviewState(true);
    } else {
      setIsValidationPass(true);
      setIsReviewState(false);
    }
  };

  useEffect(() => {
    const loadingStates = [
      countriesLoading,
      portsLoading,
      currenciesLoading,
      categoriesLoading,
      vesselTypesLoading,
      vesselFlagsLoading,
      issueTypesLoading,
      fileUploadLoading,
      portLocationsLoading,
      serviceRequestLoading,
      equipmentsLoading,
      manufacturersLoading,
    ];

    const anyLoading = loadingStates.some((loading) => loading);
    setIsLoading(anyLoading);
  }, [
    countriesLoading,
    portsLoading,
    currenciesLoading,
    categoriesLoading,
    vesselTypesLoading,
    vesselFlagsLoading,
    issueTypesLoading,
    fileUploadLoading,
    portLocationsLoading,
    serviceRequestLoading,
    equipmentsLoading,
    manufacturersLoading,
  ]);

  const onConfirmationProceeds = () => {
    setIsShowConfirmationModal(false);

    dispatch({
      type: CREATE_SERVICE_REQUEST,
      payload: {
        data: {
          ...state,
          files: [...(fileUpload ?? [])],

          serviceRequestAt: {
            ...state?.serviceRequestAt,
            eta: state?.serviceRequestAt?.eta?.[0],
            etd: state?.serviceRequestAt?.etd?.[0],
            serviceRequestOn: state?.serviceRequestAt?.serviceRequestOn?.[0],
          },
        },
      },
    });
  };

  const onConfirmationCancel = () => {
    setIsShowConfirmationModal(false);
    setIsRequestCreatedSuccessful(false);
    setIsReviewState(false);
  };

  return (
    <>
      <RequestorLayoutWrapper>
        {/* <button onClick={() => console.log("state1212", state)}>
          Get state
        </button> */}
        {isLoading && <DashboardLoader />}

        {isShowConfirmationModal && (
          <ConfirmationModal
            onProceed={onConfirmationProceeds}
            onCancel={onConfirmationCancel}
          />
        )}

        {isRequestCreatedSuccessful && <RequestPlacedSuccessfulModal />}

        <Container>
          <Heading>Vessel Details</Heading>

          <Row>
            <Col lg={4}>
              <Label showRequired={true}>Vessel Name</Label>
              <TextField
                name="vesselName"
                disabled={isReviewState}
                value={state?.vesselDetail?.name}
                onChange={handleChange}
              />
              <Error
                show={isValidationPass && !state?.vesselDetail?.name}
                message="Please Enter Vessel Name"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Vessel Type</Label>
              <SelectField
                name="vesselTypeId"
                isDisabled={isReviewState}
                options={
                  vesselTypes?.result?.map((vesselType) => ({
                    value: vesselType?.id,
                    label: vesselType?.name,
                    data: vesselType,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `vesselTypeId`)}
              />
              <Error
                show={isValidationPass && !state?.vesselDetail?.vesselTypeId}
                message="Please Select Vessel Type"
              />
            </Col>

            <Col lg={4}>
              <Label>IMO Number</Label>
              <TextField
                name="imoNumber"
                value={state?.vesselDetail?.imoNumber}
                onChange={handleChange}
                disabled={isReviewState}
              />
            </Col>

            <Col lg={4}>
              <Label>Vessel Flag</Label>
              <SelectField
                name="vesselFlagId"
                options={
                  vesselFlags?.result?.map((vesselFlag) => ({
                    value: vesselFlag?.id,
                    label: vesselFlag?.name,
                    data: vesselFlag,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `vesselFlagId`)}
                isDisabled={isReviewState}
              />
              <Error />
            </Col>
          </Row>

          <HorizontalLine />

          <Heading> Service Request At</Heading>
          <Row>
            <Col lg={4}>
              <Label showRequired={true}>Country</Label>
              <SelectField
                isDisabled={isReviewState}
                options={
                  countries?.result?.map((country) => ({
                    value: country?.id,
                    label: country?.name,
                    data: country,
                  })) ?? []
                }
                value={
                  state?.serviceRequestAt?.countryId === null
                    ? null
                    : countries?.result
                        ?.filter(
                          (country) =>
                            country?.id === state?.serviceRequestAt?.countryId
                        )
                        ?.map((country) => ({
                          value: country?.id,
                          label: country?.name,
                          data: country,
                        }))?.[0]
                }
                onChange={(data) => onSelectHandler(data, `country`)}
              />
              <Error
                show={isValidationPass && !state?.serviceRequestAt?.countryId}
                message="Please Select Country"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Port</Label>
              <SelectField
                isDisabled={
                  isReviewState || state?.serviceRequestAt?.countryId === null
                }
                options={
                  ports?.result?.map((port) => ({
                    value: port?.id,
                    label: port?.name,
                    data: port,
                  })) ?? []
                }
                value={
                  state?.serviceRequestAt?.portId === null
                    ? null
                    : ports?.result
                        ?.filter(
                          (port) => port?.id === state?.serviceRequestAt?.portId
                        )
                        ?.map((port) => ({
                          value: port?.id,
                          label: port?.name,
                          data: port,
                        }))?.[0]
                }
                onChange={(data) => onSelectHandler(data, `portId`)}
              />
              <Error
                show={
                  !(
                    isReviewState || state?.serviceRequestAt?.countryId === null
                  ) &&
                  isValidationPass &&
                  !state?.serviceRequestAt?.portId
                }
                message="Please Select Port"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Location</Label>
              <SelectField
                isDisabled={
                  isReviewState || state?.serviceRequestAt?.countryId === null
                }
                options={
                  portLocations?.result?.map((city) => ({
                    value: city?.id,
                    label: city?.name,
                    data: city,
                  })) ?? []
                }
                value={
                  state?.serviceRequestAt?.locationId === null
                    ? null
                    : portLocations?.result
                        ?.filter(
                          (city) =>
                            city?.id === state?.serviceRequestAt?.locationId
                        )
                        ?.map((city) => ({
                          value: city?.id,
                          label: city?.name,
                          data: city,
                        }))?.[0]
                }
                onChange={(data) => onSelectHandler(data, `location`)}
              />
              <Error
                show={
                  !(
                    isReviewState || state?.serviceRequestAt?.countryId === null
                  ) &&
                  isValidationPass &&
                  !state?.serviceRequestAt?.locationId
                }
                message="Please Select Location"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>E.T.A (Local Time)</Label>
              <DateField
                name="eta"
                disabled={isReviewState}
                onChange={(time) =>
                  setState((prev) => ({
                    ...prev,
                    serviceRequestAt: {
                      ...prev?.serviceRequestAt,
                      eta: time,
                    },
                  }))
                }
                options={{
                  minDate: "today",
                  enableTime: true,
                  dateFormat: "Y-m-d H:i",
                }}
              />
              <Error
                show={isValidationPass && !state?.serviceRequestAt?.eta}
                message="Please Select E.T.A (Local Time)"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true} disabled={isReviewState}>
                E.T.D (Local Time)
              </Label>
              <DateField
                name="etd"
                disabled={isReviewState}
                onChange={(time) =>
                  setState((prev) => ({
                    ...prev,
                    serviceRequestAt: {
                      ...prev?.serviceRequestAt,
                      etd: time,
                    },
                  }))
                }
                options={{
                  minDate: "today",
                  enableTime: true,
                  dateFormat: "Y-m-d H:i",
                }}
              />
              <Error
                show={isValidationPass && !state?.serviceRequestAt?.etd}
                message="Please Select E.T.D (Local Time)"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true} disabled={isReviewState}>
                Service Required On (Local Time)
              </Label>
              <DateField
                name="serviceRequestOn"
                disabled={isReviewState}
                onChange={(time) =>
                  setState((prev) => ({
                    ...prev,
                    serviceRequestAt: {
                      ...prev?.serviceRequestAt,
                      serviceRequestOn: time,
                    },
                  }))
                }
                options={{
                  minDate: "today",
                  enableTime: true,
                  dateFormat: "Y-m-d H:i",
                }}
              />
              <Error
                show={
                  isValidationPass && !state?.serviceRequestAt?.serviceRequestOn
                }
                message="Please Select Service Required On"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Bid Send To Email ID</Label>
              <TextField
                disabled={isReviewState}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    serviceRequestAt: {
                      ...prev.serviceRequestAt,
                      bidSendToEmailAddress: [e.target.value],
                    },
                  }))
                }
              />
              <Error
                show={
                  isValidationPass &&
                  !state?.serviceRequestAt?.bidSendToEmailAddress?.length > 0
                }
                message="Please Enter Bid Send To Email ID"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Bid Currency</Label>
              <SelectField
                isDisabled={
                  isReviewState || state?.serviceRequestAt?.countryId === null
                }
                options={
                  currencies?.result?.map((currency) => ({
                    value: currency?.id,
                    label: currency?.name,
                    data: currency,
                  })) ?? []
                }
                value={
                  state?.serviceRequestAt?.bidCurrencyId === null
                    ? null
                    : currencies?.result
                        ?.filter(
                          (currency) =>
                            currency?.id ===
                            state?.serviceRequestAt?.bidCurrencyId
                        )
                        ?.map((currency) => ({
                          value: currency?.id,
                          label: currency?.name,
                          data: currency,
                        }))?.[0]
                }
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    serviceRequestAt: {
                      ...prev.serviceRequestAt,
                      bidCurrencyId: e.value,
                    },
                  }))
                }
              />
              <Error
                show={
                  !(
                    isReviewState || state?.serviceRequestAt?.countryId === null
                  ) &&
                  isValidationPass &&
                  !state?.serviceRequestAt?.bidCurrencyId
                }
                message="Please Select Bid Currency"
              />
            </Col>
          </Row>

          <HorizontalLine />

          <Heading> Service Request Details</Heading>
          <Row>
            <Col lg={4}>
              <Label showRequired={true}>Equipment Category</Label>
              <SelectField
                name="equipmentCategoryId"
                isDisabled={isReviewState}
                options={
                  categories?.result?.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    data: category,
                  })) ?? []
                }
                value={
                  state?.serviceRequestDetail?.equipmentCategoryId === null
                    ? null
                    : categories?.result
                        ?.filter(
                          (category) =>
                            category?.id ===
                            state?.serviceRequestDetail?.equipmentCategoryId
                        )
                        ?.map((category) => ({
                          value: category?.id,
                          label: category?.name,
                          data: category,
                        }))?.[0]
                }
                onChange={(data) =>
                  onSelectHandler(data, `equipmentCategoryId`)
                }
              />
              <Error
                show={
                  isValidationPass &&
                  !state?.serviceRequestDetail?.equipmentCategoryId
                }
                message="Please Select Equipment Category"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Equipment Name</Label>
              <SelectField
                name="equipmentName"
                isDisabled={
                  isReviewState ||
                  state?.serviceRequestDetail?.equipmentCategoryId === null
                }
                options={
                  equipments?.result?.map((equipment) => ({
                    value: equipment?.id,
                    label: equipment?.name,
                    data: equipment,
                  })) ?? []
                }
                value={
                  state?.serviceRequestDetail?.equipmentName === ""
                    ? null
                    : equipments?.result
                        ?.filter(
                          (equipment) =>
                            equipment?.id ===
                            state?.serviceRequestDetail?.equipmentName
                        )
                        ?.map((equipment) => ({
                          value: equipment?.id,
                          label: equipment?.name,
                          data: equipment,
                        }))?.[0]
                }
                onChange={(data) => onSelectHandler(data, `equipmentName`)}
              />
              <Error
                show={
                  isValidationPass &&
                  !state?.serviceRequestDetail?.equipmentName
                }
                message="Please Enter Equipment Name"
              />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Manufacturer</Label>
              <SelectField
                name="equipmentManufacturer"
                isDisabled={
                  isReviewState ||
                  state?.serviceRequestDetail?.equipmentName === "" ||
                  state?.serviceRequestDetail?.equipmentCategoryId === null
                }
                options={
                  manufacturers?.result?.map((manufacturer) => ({
                    value: manufacturer?.id,
                    label: manufacturer?.name,
                    data: manufacturer,
                  })) ?? []
                }
                value={
                  state?.serviceRequestDetail?.equipmentManufacturer === ""
                    ? null
                    : manufacturers?.result
                        ?.filter(
                          (manufacturer) =>
                            manufacturer?.id ===
                            state?.serviceRequestDetail?.equipmentManufacturer
                        )
                        ?.map((manufacturer) => ({
                          value: manufacturer?.id,
                          label: manufacturer?.name,
                          data: manufacturer,
                        }))?.[0]
                }
                onChange={(data) =>
                  onSelectHandler(data, `equipmentManufacturer`)
                }
              />
              <Error
                show={
                  isValidationPass &&
                  !state?.serviceRequestDetail?.equipmentManufacturer
                }
                message="Please Enter Manufacturer"
              />
            </Col>

            <Col lg={4}>
              <Label>Model</Label>
              <TextField
                name="model"
                disabled={isReviewState}
                value={state?.serviceRequestDetail?.model}
                onChange={handleChange}
              />
              <Error />
            </Col>

            <Col lg={4}>
              <Label showRequired={true}>Issue Type</Label>
              <SelectField
                name="issueType"
                isDisabled={isReviewState}
                options={
                  issueTypes?.result?.map((issueType) => ({
                    value: issueType.id,
                    label: issueType?.name,
                    data: issueType,
                  })) ?? []
                }
                onChange={(data) => onSelectHandler(data, `issueType`)}
              />
              <Error
                show={
                  isValidationPass && !state?.serviceRequestDetail?.issueType
                }
                message="Please Select Issue Type"
              />
            </Col>
          </Row>

          <HorizontalLine />
          <Row>
            <Col lg={12}>
              <Label>Description of Problem / Service Required</Label>
              <TextAreaField
                name="serviceDescription"
                disabled={isReviewState}
                value={state?.serviceDescription}
                onChange={handleChange}
                rows="8"
                maxLength={3000}
              />
              <Error />
            </Col>
          </Row>

          <HorizontalLine />

          <div className="text-end">
            <FileInput disabled={isReviewState} onChange={handleUpdate} />
          </div>
          <HorizontalLine />

          <div className="uploaded-cocument">
            {fileUpload?.length > 0 &&
              fileUpload.map((_, index) => (
                <div className="bg-infos" key={index}>
                  <div className="uploaded-document-name">{`Document ${
                    index + 1
                  }`}</div>
                  <RxCross2
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch({
                        type: DELETE_FILE,
                        payload: _,
                      });
                    }}
                  />
                </div>
              ))}
          </div>
          {fileUpload?.length > 0 && <HorizontalLine />}

          <Label>Bid (quotes) needed in:</Label>

          <Row>
            <Col md={3}>
              <div className="">
                <select
                  className="form-select"
                  value={selectedValue}
                  disabled={isReviewState}
                  onChange={handleChange}
                  style={{
                    backgroundColor: isReviewState
                      ? "var(--disable)" // Set the background color when disabled
                      : selectedValue === "72-60 Hrs"
                      ? "#28a745"
                      : selectedValue === "59-30 Hrs"
                      ? "#fd7e14"
                      : selectedValue === "29-01 Hrs"
                      ? "#dc3545"
                      : "#fff",
                    color: isReviewState
                      ? "#6c757d" // Set the text color when disabled
                      : selectedValue !== "72 Hours"
                      ? "#fff"
                      : "#000",
                  }}
                >
                  {/* <option value="72 Hours">72 Hours</option> */}
                  <option
                    value="72-60 Hrs"
                    style={{ backgroundColor: "#28a745", color: "#fff" }}
                  >
                    72-60 Hrs
                  </option>
                  <option
                    value="59-30 Hrs"
                    style={{ backgroundColor: "#fd7e14", color: "#fff" }}
                  >
                    59-30 Hrs
                  </option>
                  <option
                    value="29-01 Hrs"
                    style={{ backgroundColor: "#dc3545", color: "#fff" }}
                  >
                    29-01 Hrs
                  </option>
                </select>
              </div>
            </Col>

            <Col md="2">
              {subOptions?.length > 0 && (
                <select
                  disabled={isReviewState}
                  className="form-select"
                  onChange={(data) => onSelectHandler(data, `bidTime`)}
                >
                  {subOptions?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            </Col>

            <Col md="7">
              <div className="d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center me-4">
                  <div
                    className="bg-danger"
                    style={{ width: "20px", height: "20px" }}
                  ></div>
                  <span className="ms-2">Top Urgent</span>
                </div>

                <div className="d-flex align-items-center me-4">
                  <div
                    className="bg-warning"
                    style={{ width: "20px", height: "20px" }}
                  ></div>
                  <span className="ms-2">Urgent</span>
                </div>

                <div className="d-flex align-items-center">
                  <div
                    className="bg-success"
                    style={{ width: "20px", height: "20px" }}
                  ></div>
                  <span className="ms-2">General</span>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="mt-3 d-flex justify-content-end gap-3">
              {isReviewState ? (
                <>
                  <SecondaryButton
                    name="Amend"
                    onClick={() => setIsReviewState(false)}
                  />
                  <PrimaryButton name="Submit" onClick={() => handleSubmit()} />
                </>
              ) : (
                <>
                  <SecondaryButton
                    name="Cancel"
                    onClick={() =>
                      navigate(AppRoutes?.CUSTOMER_REQUESTOR_DASHBOARD)
                    }
                  />
                  <PrimaryButton
                    name="Review"
                    onClick={() => checkValidations()}
                  />
                </>
              )}
            </div>
          </Row>
        </Container>
      </RequestorLayoutWrapper>
    </>
  );
}
