import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_CURRENCIES,
  GET_CURRENCIES_ERROR,
  GET_CURRENCIES_SUCCESS,
} from "../../actionTypes.js";

function* getCurrencies() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/master/v1/country-currency`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_CURRENCIES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CURRENCIES_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CURRENCIES_ERROR, payload: error });
  }
}

export function* watchGetCurrencies() {
  yield takeEvery(GET_CURRENCIES, getCurrencies);
}

function* getCurrenciesSaga() {
  yield all([fork(watchGetCurrencies)]);
}

export default getCurrenciesSaga;
