import axios from "axios";

// Create an instance of axios
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // sessionStorage.clear();
    // localStorage.clear();
    // window.open(AppRoutes.login, "_self");
    return Promise.reject(error);
  }
);

export default async function HttpClient(config) {
  const isFormData = config?.isFormData;

  const headers = {
    ...config?.headers,
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };

  try {
    const response = await axiosInstance({
      url: config?.url,
      method: config?.method,
      headers: headers,
      params: config?.params,
      data: config?.data ?? {},
    });
    return response;
  } catch (err) {
    return err;
  }
}
