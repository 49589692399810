import React from "react";

import Sidebar from "../../../../../components/common/Sidebar";
import { DashboardConstants } from "../../../../../components/constants";
import { Footer, Header } from "../../../../../components/common/index";
import Breadcrumb from "../../../../../components/common/Breadcrumb";
import AppRoutes from "../../../../../components/constants/app-routes";

export default function RequestorLayoutWrapper({
  children,
  breadCrumbContent,
}) {
  return (
    <div className="layout-wrapper">
      <Header />
      <main className="d-flex">
        <Sidebar sidebar={DashboardConstants?.CustomerRequestorMenus} />

        <section className="main-area position-relative dashboard-container ">
          <Breadcrumb
            path={AppRoutes.CUSTOMER_REQUESTOR_DASHBOARD}
            content={breadCrumbContent}
          />
          {children}
        </section>
      </main>
      <Footer />
    </div>
  );
}
