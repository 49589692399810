import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FaPlus } from "react-icons/fa";

import {
  DashboardLoader,
  SecondaryButton,
} from "../../../../../components/common";
import Card from "../../../../../components/common/Card";
import { AppRoutes } from "../../../../../components/constants";
import { GET_USER_DASHBOARD } from "../../../../../store/apis/actionTypes";
import RequestorLayoutWrapper from "../RequestorLayout";

export default function CustomerRequestorDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // DASHBOARD DATA
  const { getDashboardData, getDashboardDataLoading } = useSelector(
    (state) => ({
      getDashboardData: state?.UserDashboard?.data?.itemList,
      getDashboardDataLoading: state?.UserDashboard?.loading,
    })
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!getDashboardData) {
      dispatch({
        type: GET_USER_DASHBOARD,
      });
    }
  }, [dispatch]);

  const handleNavigate = () => {
    navigate(AppRoutes?.CUSTOMER_REQUESTOR_ADD_NEW_REQUEST);
  };

  return (
    <RequestorLayoutWrapper
      breadCrumbContent={
        <SecondaryButton
          name="Add New Request"
          icon={<FaPlus />}
          onClick={handleNavigate}
        />
      }
    >
      {getDashboardDataLoading && <DashboardLoader />}

      <div className="cardGroup">
        {(getDashboardData ?? [])?.map((item, index) => (
          <Card
            key={index}
            label={item?.name}
            path={item?.redirectionLink}
            number={item?.count}
          />
        ))}
      </div>
    </RequestorLayoutWrapper>
  );
}
