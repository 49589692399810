import { useLocation, useNavigate } from "react-router-dom";

export default function Sidebar({ sidebar, type }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="sidebar">
      <ul>
        {sidebar?.map((item, index) => (
          <li
            key={index}
            onClick={() => handleNavigation(item?.path)}
            className={`linkList ${
              location?.pathname === item?.path ? "active" : ""
            }`}
          >
            {location?.pathname === item?.path && <div className="pipe"></div>}
            {/* <span className="me-2">
              <RiDashboard2Line />
            </span> */}
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
