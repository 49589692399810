import { Footer, Header } from '../../../components/common';

export default function PrivacyPolicy() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />

      <main className="section_privacy_policy">
        <section className="section_privacy_container">
          <div className="privacyPolicyHeading">Privacy Policy</div>
          <div className="termAndConditionContainer">
            <div className="termAndConditionContent termAndConditionText">
              <p className="body">
                <span className="termAndConditionTextArea">
                  Ath Marine is committed to protecting the personal information of its users - Customers, Partners and other visitors browsing the
                  Site (“Users”) as well as affiliates, influencers and other collaborators interacting with Ath Marine. We believe that you have a
                  right to know our practices regarding the information we collect when you visit and/or use the Site. This Policy explains our
                  privacy practices for the Site and services provided by Ath Marine as well as privacy practices that apply to affiliates,
                  influencers and other collaborators who are individuals. By accessing and/or using the Site and its related sites, applications,
                  services, goods and/or registering for a Ath Marine account and/or registering to our affiliate or influencer or similar program,
                  you acknowledge that you understand how we process your personal data in accordance with this Policy, including our collection, use,
                  disclosure, processing, and retention of personal information. You can also learn how to limit sharing of information in this
                  Policy.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Information We Collect</p>
              <p className="termAndConditionPrimaryHeading">Information You Provide.</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  When you register to the Site, use it, complete forms, or use the Site as a Partner or Customer, and/or register for any other use,
                  we ask you to provide certain personal information, including a valid email address and mobile number. We will also ask you to
                  provide or otherwise collect additional information, such as, your profile details, physical address or billing information, or
                  other contact details, transactional information, payment information (for example, in certain cases we process your payment method
                  and credit card number), taxpayer information and forms, education, profession and expertise, and additional authentication
                  information (such as copies of your government issued ID, passport, or driving license, as permitted by applicable laws. We also
                  collect information about or contained in your communications with Ath Marine as well as any of your posts on our blogs or forums
                  and your communication with other users of Ath Marine.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionPrimaryHeading">Information We Collect Automatically.</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  We collect information while you access, browse, view or otherwise use the Site. In other words, when you access the Site we collect
                  personal information on your usage of the Site, including transactions and communications with other users through the Site, your
                  searches, the pages you visited, as well as other actions on Site. We Also, collect, use and process the information relating to
                  such usage, including geo-location information, IP address, device and connection information, browser information and web-log
                  information, and the URLs of the web pages you’ve viewed or engaged with before or after using the Site. We also collect and process
                  information relating to the use of cookies and similar technologies. We use that information to provide you our services, enhance
                  user experience, personalize your browsing experience as well as monitor the Site for preventing fraud and inappropriate content or
                  behaviour.
                </span>
                <span className="termAndConditionTextArea">
                  Additionally, in order to improve your online experience at Ath Marine, we have implemented impression reporting. While you view our
                  ads / banners, we gather user Global Unique Identifier, HTTP request data like, user agent, IP, host, URL, country/continent from
                  which request made, browser info, device/operating system/operating system version.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionPrimaryHeading">Information We Receive from Third Parties.</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  We receive information from third party vendors and/or other commercially available sources, such as:
                </span>
              </p>
              <ul className="listing">
                <li>
                  Third party vendors who provide us data to supplement the information we collect about you, in accordance with applicable laws. For
                  example, we receive fraud warnings from service providers for our fraud prevention and risk assessment efforts. We also receive
                  information from enrichment service providers to enhance and personalize your experience as well as from advertising and marketing
                  partners in order to manage and measure our campaigns.
                </li>
                <li>
                  If you link, connect, or login to your Ath Marine Account with a third-party service (e.g. Google, Facebook, LinkedIn), we receive
                  certain information, such as your registration and profile information from that service. This information varies and is controlled
                  by that service or as authorized by you via your privacy settings at that service.
                </li>
                <li>
                  We also receive certain information about you, if you are invited to Ath Marine by another user (for example, as part of our
                  collaboration tools and referral program), in such case we will receive limited information, such as your email address or other
                  contact information, and we will use it for the same purpose it was provided and in accordance with the terms of this Privacy
                  Policy.
                </li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Our Legal Basis for Using Your Personal Information</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  Where relevant under applicable laws, all processing of your personal information will be justified by a "lawful ground" for
                  processing. In the majority of cases, processing will be justified on the basis that:
                </span>
              </p>
              <ul className="listing">
                <li>you have provided your consent for us to use your personal information for a specific purpose;</li>
                <li>
                  our use of your personal information is necessary to perform a contract or take steps to enter into a contract with you (e.g. to
                  provide you with services which you have purchased);
                </li>
                <li>
                  the processing is necessary to comply with a relevant legal obligation or regulatory obligation that we have (e.g. fraud
                  prevention); or
                </li>
                <li>
                  the processing is necessary to support our legitimate interests as a business (e.g. to improve our services to you), subject to your
                  interests and fundamental rights and provided it is conducted at all times in a way that is proportionate.
                </li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">How Do We Use The Information Collected?</p>
              <p className="body">
                <span className="termAndConditionTextArea">We will use your personal information for the following purposes:</span>
              </p>
              <ul className="listing">
                <li>
                  to provide you with quality service and security, to operate the Site, to perform our obligations to you and to develop and improve
                  our service. For example, we use personal information to verify your identity. We also use this information to establish and set up
                  your account, verify or re-issue a password, log your activity, enable your communications with other members, provide customer
                  support and contact you from time to time. The information helps us to develop and improve our services, to understand and analyse
                  our performance as well as your preferences and performance and to customize and personalize our service and enhance your
                  experience.
                </li>
                <li>
                  to ensure marketplace integrity, prevent fraud and maintain a safe and secure marketplace and services. For example, we use personal
                  information to monitor, track and prevent fraudulent activities and other inappropriate activities, monitor content integrity,
                  conduct security investigations and risk assessments, verify or authenticate information provided by you, enforce our Terms of
                  Service and comply with applicable laws. We conduct certain behavioural analytics to achieve the above objectives and in limited
                  cases, if we detect activity that we think poses a risk to the Ath Marine marketplace, other users, our community, or third parties,
                  automated processes may restrict or limit your ability to use Ath Marine. If you would like to challenge any such a decision, please
                  contact us at <a href="mailto:marketing@athmarine.com">marketing@athmarine.com</a>.
                </li>
                <li>to contact you, as requested by you or as otherwise approved by you or permitted according to this Policy.</li>
                <li>
                  to promote and advertise the Site, our services, and the Ath Marine marketplace. For example, we use the information collected from
                  you for the purpose of sending direct marketing messages, to show you information that may be of interest to you, to organize and
                  facilitate referral programs, contests or other promotional activities or events.
                </li>
                <li>
                  to maintain appropriate business records, to comply with lawful requests by public authorities and to comply with applicable laws
                  and regulations or as otherwise required by law.
                </li>
              </ul>
              <p className="termAndConditionBottomtext">
                <span className="termAndConditionTextArea">
                  We will ask for your consent before using information for a purpose other than those set out in this Policy.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Direct marketing:</p>
              <ul className="listing">
                <li>
                  We use your personal information to send you direct marketing communications about our products, services or promotions from Ath
                  Marine that may be of interest to you or our related services. This may be via email, post, SMS, telephone or targeted online
                  advertisements. In most cases, our processing of your personal information for marketing purposes is based on our legitimate
                  interest, although some activities (such as where required by law) are based on your prior consent. You have a right to prevent
                  direct marketing of any form at any time – this can be exercised by following the opt-out link attached to each communication, by
                  changing privacy settings in your Ath Marine account or by contacting us at{' '}
                  <a href="mailto:marketing@athmarine.com">marketing@athmarine.com</a>.
                </li>
                <li>
                  We take steps to limit direct marketing to a reasonable and proportionate level and to send you communications which we believe may
                  be of interest or relevance to you, based on the information we have about you.
                </li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">How Long Do We Keep Personal Information</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  We apply a general rule of keeping personal information only for as long as required to fulfill the purposes for which it was
                  collected. However, in some circumstances, we may retain your personal information for longer periods of time. We will retain
                  personal information for the following purposes:
                </span>
              </p>
              <ul className="listing">
                <li>
                  As long as it is necessary and relevant for our operations (e.g., so we have an accurate record of your dealings with us in the
                  event of any complaints or challenge); and
                </li>
                <li>
                  We may also retain personal information from closed accounts to comply with national laws, prevent fraud, collect any fees owed,
                  resolve disputes, troubleshoot problems, assist with any investigation, enforce our Site terms and take other actions as permitted
                  by law.
                </li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Children</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  This Site is offered and available to users who are 16 years of age or older. If you are under 16 you may not use this Site or the
                  Ath Marine services. We do not knowingly collect personal information from users under the age of 16. If we become aware that
                  personal information has been collected from a user under 16 we will delete that information. If you believe that we may have any
                  information from or about a child under the age of 16, please contact us at{' '}
                  <a href="mailto:marketing@athmarine.com">marketing@athmarine.com</a>.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Security</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  We implement technical and organizational measures to maintain the security of the Site, our services, and personal information, and
                  in preventing unauthorized access, loss, misuse, alteration, destruction, or damage to it through industry-standard technologies and
                  internal procedures. In addition, we contractually ensure that any third party processing your personal information equally provides
                  for confidentiality and integrity of your data in a secure way. However, the transmission of data via the internet is not completely
                  secure, and although we will do our best to protect your personal information, we cannot guarantee the security of your data
                  transmitted to the Site. Once we have received your data, we will use strict procedures and security features to try to prevent
                  unauthorized access. Users who have registered to the Site agree to keep their password in strict confidence and not disclose such
                  password to any third party. Further information about our data security practices can be provided on request.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Rights of EU, EEA and UK Users</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  This section of the Policy applies to you if you are in the EU, the European Economic Area (EEA), or the UK.
                </span>
                <span className="termAndConditionTextArea">
                  The Controller (within the meaning of the EU and UK General Data Protection Regulation (“GDPR”)) for the processing of personal data
                  as described in this Privacy Policy is: Ath Marine Pte. Ltd., Singapore.
                </span>
                <span className="termAndConditionTextArea">
                  Under applicable EU regulation, you have the following rights in respect of your personal information:
                </span>
              </p>

              <ul className="listing">
                <li>to obtain information about how and on what basis your personal information is processed and to obtain a copy;</li>
                <li>to rectify inaccurate personal information;</li>
                <li>
                  to erase your personal information in limited circumstances where (a) you believe that it is no longer necessary for us to hold your
                  personal information; (b) we are processing your personal information on the basis of legitimate interests and you object to such
                  processing, and we cannot demonstrate an overriding legitimate ground for the processing; (c) where you have provided your personal
                  information to us with your consent and you wish to withdraw your consent and there is no other ground under which we can process
                  your personal information; and (d) where you believe the personal information we hold about you is being unlawfully processed by us;
                </li>
                <li>
                  to restrict processing of your personal information where: (a) the accuracy of the personal information is contested; (b) the
                  processing is unlawful but you object to the erasure of the personal information; (c) we no longer require the personal information
                  for the purposes for which it was collected, but it is required for the establishment, exercise, or defense of a legal claim or (d)
                  you have objected to us processing your personal information based on our legitimate interests and we are considering your
                  objection;
                </li>
                <li>to object to decisions which are based solely on automated processing or profiling;</li>
                <li>
                  where you have provided your personal information to us with your consent, to ask us for a copy of this data in a structured,
                  machine-readable format and to ask us to share (port) this data to another data controller; or
                </li>
                <li>to obtain a copy of or access to safeguards under which your personal information is transferred outside of the EEA.</li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">
                Under certain circumstances, you may have the right to object, on grounds relating to your particular situation, to the processing of
                your personal data by us and we may be required to no longer process your personal data. Moreover, if your personal data is processed
                for direct marketing purposes, you have the right to object at any time to the processing of personal data concerning you for such
                marketing, which includes profiling to the extent that it is related to such direct marketing. In this case, your personal data will
                no longer be processed for such purposes by us.
              </p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  In addition to the above, you have the right to lodge a complaint with a supervisory authority for data protection. Please note that
                  the right of access and the right to erasure do not constitute absolute rights and the interests of other individuals may restrict
                  your right of access or erase in accordance with local laws.
                </span>
                <span className="termAndConditionTextArea">
                  We will ask you for additional data to confirm your identity and for security purposes, before disclosing data requested by you. We
                  reserve the right to charge a fee where permitted by law. We will decline to process requests that jeopardize the privacy of others,
                  are extremely impractical, or would cause us to take any action that is not permissible under applicable laws. Additionally, as
                  permitted by applicable laws, we will retain where necessary certain personal information for a limited period of time for
                  record-keeping, accounting, and fraud prevention purposes.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Specific Provisions for California Residents</p>
              <p className="body">
                <span className="termAndConditionTextArea">This section of the Policy applies to you, if you are a California resident.</span>
                <span className="termAndConditionTextArea">
                  During the last twelve (12) months we have collected the following categories of personal information from Users:
                </span>
              </p>
              <ul className="listing">
                <li>
                  Information that you chose to upload or otherwise provided by you to Ath Marine, which may include: (i) Identifiers and personal
                  information, such as name, postal addresses, online identifiers, email addresses, passport number or driving license number, social
                  security number; (ii) characteristics of protected classifications, such as gender; facial image; audio, electronic, or similar
                  information; (iii) professional or employment-related information; (iv) education information; (v) commercial information; (vi)
                  Audio or other sensory information, for example, if you provide audio-based services on Ath Marine.
                </li>
                <li>
                  Information we collect when you use Ath Marine, including (i) Identifiers and personal information, such as online identifiers,
                  internet protocol (IP) addresses, access device and connection information such as browser type, version, and time zone setting and
                  browser plug-in types and versions; (ii) commercial information, including products or services purchased, obtained, or considered,
                  or other purchasing or consuming histories or tendencies; (iii) Internet or other electronic network activity information,
                  including, but not limited to log-in and log-out time, the duration of sessions, the content uploaded and downloaded, viewed web
                  pages or specific content on web pages, activity measures; (iv) Location information, based on your IP address.
                </li>
                <li>
                  Information that we collect or receive from third parties, such as service providers, advertisers, and third-party accounts you link
                  with Ath Marine, including: (i) Identifiers and personal information, such as name, online identifiers, email addresses, internet
                  protocol (IP) addresses, access device and connection information such as browser type, version, and time zone setting and browser
                  plug-in types and versions; (ii) professional or employment-related information; (iii) Internet or other electronic network activity
                  information, including, but not limited to log-in and log-out time, the duration of sessions, the content uploaded and downloaded,
                  viewed web pages or specific content on web pages, activity measures; and (iv) Location information.
                </li>
                <li>Inferences drawn from any of the information identified above to create a profile about you.</li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="body">
                <span className="termAndConditionTextArea">
                  We use the personal information that we collect or receive for the business purposes as described above under the Section titled
                  “How Do We Use the Information Collected?”.
                </span>
                <span className="termAndConditionTextArea">
                  We may disclose the above-listed categories of personal information to third parties for business purposes as described above under
                  the Section titled “Sharing Personal Information with Third Parties” in the Privacy Policy. In the preceding twelve (12) months, we
                  have disclosed all the categories of personal information detailed above for business purposes.
                </span>
                <span className="termAndConditionTextArea">
                  As previously mentioned in this Policy, we do not “sell” (as such term is defined in the CCPA) personal information.
                </span>
                <span className="termAndConditionTextArea">
                  You are entitled to the following specific rights under the CCPA in relation to personal information related to you:
                </span>
              </p>
              <ul className="listing">
                <li>to request disclosure of the categories and specific pieces of personal information collected about you;</li>
                <li>to request disclosure of the categories of sources from which the personal information is collected;</li>
                <li>to request disclosure of the business purpose for collecting or selling personal information;</li>
                <li>
                  to request disclosure of the categories of third parties with whom we share personal information, and the categories of personal
                  information that we have shared with service providers who provide services to us;
                </li>
                <li>to request the deletion of your personal information;</li>
                <li>not to be discriminated against by exercising your rights under the CCPA.</li>
              </ul>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="body">
                <span className="termAndConditionTextArea">
                  You may submit a request for the information described above by sending an email to [privacy email]. To protect your data, we will
                  ask you for additional data to confirm your identity and for security purposes, before disclosing data requested by you.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Children</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  Our Site and services are not intended for children under the age of 18. If we become aware that we have collected personal
                  information from a child under the age of 18, we will take steps to delete such information without delay. If you become aware that
                  a child has provided us with personal information, please contact us immediately so that we can delete such information.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Updates or Amendments to the Privacy Policy</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  We may revise this Privacy Policy from time to time. The most current version of the policy will govern our use of your information
                  and will be available on the Site. We may make material changes to this policy and will notify you of such changes by posting them
                  on the Site or by sending you an email or other notification, where feasible, of any material changes. We encourage you to review
                  this policy periodically to stay informed of our practices.
                </span>
              </p>
            </div>
            <div className="termAndConditionContent termAndConditionText">
              <p className="termAndConditionMainHeading">Contact Us</p>
              <p className="body">
                <span className="termAndConditionTextArea">
                  If you have any questions, concerns, or requests with respect to this Privacy Policy or how we handle your personal information,
                  please contact us at:
                </span>
              </p>
              <p className="body">
                <span className="termAndConditionTextArea">Email: [contact email] Postal address: [postal address]</span>
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
