import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import HttpClient from './../../../../../config/http-client.js';
import { baseUrl, headers, authToken } from '../../../../../config/index.js';
import { CREATE_SERVICE, CREATE_SERVICE_SUCCESS, CREATE_SERVICE_ERROR } from '../../../actionTypes.js';

function* createVendorService({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/admin/bookings`,
      method: 'POST',
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_SERVICE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_SERVICE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_SERVICE_ERROR, payload: error });
  }
}

export function* watchCreateVendorService() {
  yield takeEvery(CREATE_SERVICE, createVendorService);
}

function* createVendorServiceSaga() {
  yield all([fork(watchCreateVendorService)]);
}

export default createVendorServiceSaga;
