import { Footer, Header } from '../../../components/common';

export default function RulesGuidelines() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />
      <main className="section_guidelines">
        <section className="guidelines_container">
          <div className="ruleGuideLinesHeading">Rules & Guidelines</div>
          <div className="RuleAndGuideLinesContainer">
            <div className="RuleGuideLinesContentContainer">
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  We’re cultivating a space for a maritime community that wants to play a part in changing how the maritime world works together. We
                  are building a global network of service providers and maritime professionals across the globe, who use Ath Marine every day to
                  ensure their vessels sail safely and efficiently. We’re inspired by each and every one of our Partners. We value their perspectives,
                  beliefs, and views—they are what makes Ath Marine so dynamic.
                </p>
                <p className="RuleAndGuideParagraph">
                  In order to maintain a respectful, inclusive, and safe environment for everyone, we’ve created a set of standards to serve as a
                  moral compass for behaviour on our platform. These standards help define what is acceptable in the Ath Marine maritime community and
                  marketplace, both in face-to-face interactions and online communications. They aren’t tied to any law but are used to govern our
                  platform policies. They reflect our attitudes, expectations, and social norms. Our maritime community standards are rooted in our
                  core values — respect, safety, acceptance, support, and open-mindedness.
                </p>
                <p className="RuleAndGuideParagraph">
                  Just like our maritime community, these standards will continue to grow, evolve, and change. We have embedded examples in the
                  maritime community standards for unacceptable activities on Ath Marine. Please note that these are intended to be examples only and
                  not a complete list.
                </p>
                <p className="RuleAndGuideParagraph">
                  We encourage our maritime community to use our reporting tools when encountering anything that is unacceptable. We want to create
                  the best experience for all maritime community members and ask that you respect and follow these guidelines.
                </p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Integrity and Authenticity</p>
              <p className="RuleGuideLinesSecondryHeading">Coronavirus (COVID-19) policies</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  At these sensitive times, we are cautious regarding Coronavirus (COVID-19) related services that we allow on Ath Marine. For
                  example, we do not allow users to offer or ask for services that are related to healing Coronavirus or creating content regarding
                  Coronavirus that may be used for spreading misleading information. Should you see an item that violates our policies, please don’t
                  hesitate to report it. Please Do not Offer or ask for:
                </p>
              </div>
              <ul className="listing">
                <li>Services that are intended to heal, prevent or treat Coronavirus (COVID-19), including emotional therapy.</li>
                <li>
                  The creation of medical, scientific or informative content exclusively on Coronavirus (COVID-19). Ath Marine is committed to
                  fighting the spread of fake news regarding Coronavirus. Therefore, if a service is reported or flagged as being used to create or
                  promote the spread of fake news; we will investigate and take action accordingly.
                </li>
                <li>Donations or investments.</li>
                <li>Face Masks.</li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  We encourage strict compliance of all Covid-19 regulations, local and international applicable to all vessels calling different
                  ports across the world. We encourage everyone to take all measures appropriate for the safety of self and of others, while executing
                  services onboard.
                </p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Misrepresentation</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph"></p>
              </div>
              <ul className="listing">
                <li>
                  Misrepresentation is defined as fraudulent, negligent, or innocent misstatements, or an incomplete statement of a material fact.
                </li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Misleading profile information</p>
              <p className="RuleGuideLinesSecondryHeading"></p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">Do not:</p>
              </div>
              <ul className="listing">
                <li>Post a fake name, location, gender, or photo in your user profile.</li>
                <li>Present a fake document(s) if asked to verify your identity.</li>
                <li>Write misleading content in your profile description, skills, or education.</li>
                <li>Provide Licensed services without the right license.</li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Spam</p>
              <p className="RuleGuideLinesSecondryHeading"></p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  We define spam on Ath Marine as a messaging activity that is unwanted, repeatedly occurring, and disruptive to the experience of
                  recipients. Spam can take many forms ranging from unwanted offers to commercially motivated solicitations that aim to drive traffic
                  or attention to websites, products, and services outside of Ath Marine. Please respect our members’ privacy by not contacting them
                  with offers, questions, suggestions, or anything which is not directly related to the required service.
                </p>
                <p className="RuleAndGuideParagraph">Do not:</p>
              </div>
              <ul className="listing">
                <li>Contact our users with offers, questions, suggestions, or anything unrelated to the current service.</li>
                <li>Send multiple unsolicited or deceptive messages.</li>
                <li>Abuse our communication tools to distribute mass messages.</li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Prohibited Services</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  Ath Marine is an open marketplace where users can offer or request a great variety of services. However, for a number of reasons, we
                  prohibit certain types of services on Ath Marine. Some services can pose unwanted risks for our users like legal or financial risks;
                  others are simply not in line with our values and policies.
                </p>
                <p className="RuleAndGuideParagraph">Here are the type of services prohibited on Ath Marine:</p>
              </div>
              <p className="RuleGuideLinesPrimaryHeading">Services that can pose legal risks for our users</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">Ath Marine is an open marketplace where users can offer or Do not offer or ask for:</p>
              </div>
              <ul className="listing">
                <li>Illegal services.</li>
                <li>Services that are meant to support any illegal activity.</li>
                <li>
                  Fake documents — the creation, changing, or editing of official documents such as IDs, passports, drivers license, bank statements,
                  or death and birth certificates.
                </li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Services that can pose financial risks for our users</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph"> Do not offer or ask for:</p>
              </div>
              <ul className="listing">
                <li>Services that require getting access to the buyer’s sensitive financial information.</li>
                <li>Opening, managing, or implementing changes in a financial account.</li>
                <li>Providing loans.</li>
                <li>Trading signals or indicators.</li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Services that have a high risk of piracy or hacking</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">Do not offer or ask for:</p>
              </div>
              <ul className="listing">
                <li>Verification services</li>
                <li>Hacking or cracking services.</li>
                <li>Services involving hacked or cracked software products (themes, templates, etc).</li>
                <li>Setup pirate accounts.</li>
              </ul>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Services that infringe third parties’ terms of service</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  We do not allow the infringement of third parties’ terms of service on Ath Marine. As a valued member of the Ath Marine maritime
                  community, we ask you to respect the broader Internet maritime community and refrain from such violations in your service offerings.
                </p>
                <p className="RuleAndGuideParagraph">Do not post, offer, or ask for services that infringe third parties’ terms of service.</p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Misleading Services</p>
              <div className="RuleAndGuideText">
                <p
                  className="RuleAndGuideParagraph

"
                >
                  Do not post, offer, or ask for:
                </p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Paid reviews</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">Positive or negative feedback on any product, service, or performance.</p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Testimonials</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">A written or spoken statement of a person extolling the virtues of a product or a service.</p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Fake engagement and traffic</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  Followers, traffic, visitors, fans, views on social media platforms, or any other fake engagement on any platform.
                </p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Deepfakes</p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  Deepfakes are created using artificial intelligence technology that superimposes the movements and words of one person onto another.
                </p>
              </div>
            </div>
            <div className="RuleGuideLinesContentContainer">
              <p className="RuleGuideLinesPrimaryHeading">Services that promise outcomes that are not in your control</p>
              <p className="RuleGuideLinesSecondryHeading"></p>
              <div className="RuleAndGuideText">
                <p className="RuleAndGuideParagraph">
                  In certain instances, Partners may be tempted to promise outcomes of their service which is not in their control. Please refrain
                  from promising outcomes that are not in your control because it misleads buyers.
                </p>
              </div>
            </div>
            Here's the updated HTML with the class names adjusted as per your request: ```html
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Services That involve sharing personal information of third parties</p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  We do not allow services that breach 3rd-party rights and lead to spamming individuals. In order to ensure this policy, we ask that
                  the data provided in these services will be unique to each order and will only contain business data. Do not post, offer, or ask
                  for:
                </p>
              </div>
              <ul class="listing">
                <li>Lists with data (such as email addresses or phone numbers) of individuals, only business data.</li>
                <li>Premade lists containing personal data.</li>
                <li>Scraping social media platforms to obtain information about others.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Medical-related services</p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">We do not allow services that are meant to provide any medical treatment, advice, or supply.</p>
                <p class="RuleAndGuideParagraph">Do not post, offer, or ask for:</p>
              </div>
              <ul class="listing">
                <li>Medical treatment, advice on any medical condition, or pharmaceutical advice.</li>
                <li>Any kind of medical drugs or pharmaceuticals.</li>
                <li>Medical equipment or pharmaceutical supply.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Objectionable Content</p>
              <p class="RuleGuideLinesSecondryHeading">Hate Speech</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Ath Marine defines hate speech as words or ideas that are designed to incite violence or hatred against a person, or group of
                  people, defined on the basis of race, ethnicity, sexual orientation, gender, marital status, religion, age, disability, or other
                  similar ground. Ath Marine is committed to moving swiftly and aggressively to investigate conduct that has been flagged as hate
                  speech, and will respond in a manner that is consistent with existing laws and the values of the company. We take hate speech
                  violations very seriously, and anyone using hate speech towards another user or towards our customer support representatives will be
                  removed from Ath Marine. We will also take action against any user asking or delivering hate speech-related content on Ath Marine.
                  Do not:
                </p>
              </div>
              <ul class="listing">
                <li>
                  Use violent or dehumanizing speech against a person or group of people defined on the basis of race, ethnicity, sexual orientation,
                  gender, marital status, religion, age, disability, or other similar ground.
                </li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  We do allow people to offer or ask for content regarding the research of hate speech or content that is related to the prevention of
                  hate speech.
                </p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Discrimination</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Under Ath Marine's maritime community standards, discrimination means unfair or unequal treatment of an individual or group based on
                  age, disability, ethnicity, gender, marital status, race, religion, and sexual orientation. Ath Marine will investigate claims of
                  discrimination on a case-by-case basis and defend protected groups whenever possible.
                </p>
                <p class="RuleAndGuideParagraph">Do not:</p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading"></p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph"></p>
              </div>
              <ul class="listing">
                <li>
                  Express your unwillingness to work with a person or a group of people based on age, disability, ethnicity, gender, marital status,
                  race, religion, sexual orientation, or other similar ground.
                </li>
                <li>Publish, offer, or ask for any content that expresses or supports discriminatory ideas.</li>
                <li>Offer or ask for the writing or editing of texts that support discriminatory ideas or practices.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Adult, Nudity, and Sexual Activity</p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Explicit content meant to be sexually gratifying is not allowed on Ath Marine. If detected or reported, sexually explicit content
                  will be blocked and participants could be banned from the marketplace.
                </p>
                <p class="RuleAndGuideParagraph">Do not post, offer or ask for:</p>
              </div>
              <ul class="listing">
                <li>Nude or adult related images, or videos.</li>
                <li>Sex or sexual partners.</li>
                <li>Sex chats or conversations.</li>
                <li>Content that depicts explicit sexual activity, unless the conditions for “documentary exception,” as listed above, exist.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Ath Marine will act quickly to block transactions that feature nudity and will flag the offending account for review.
                </p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Fake News</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Ath Marine defines Fake News as false or intentionally misleading information or propaganda that is developed and presented as
                  authentic news. If it is reported or flagged that Ath Marine's platform is being used to create or promote the spread of fake news;
                  we will investigate and take action accordingly.
                </p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Political and Election policies</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Ath Marine will not tolerate attempts to use the Ath Marine marketplace to undermine elections. Election Interference is defined as
                  any attempt to interfere with the voting process or to destabilize and undermine public faith in free and fair elections.
                </p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Political affiliation</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  A user's political affiliation is not a basis for rejecting an offer on Ath Marine. Do not express your unwillingness to work with a
                  person or a group of people based on political affiliation.
                </p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Intellectual Property</p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Ath Marine content is based on User Generated Content (UGC). If you believe any of the uploaded content violates your copyright or a
                  related exclusive right, you should write to
                  <a href="mailto:marketing@athmarine.com">marketing@athmarine.com</a>.
                </p>
                <p class="RuleAndGuideParagraph">
                  Upon receipt of a report from a rights holder or an authorized representative, we will remove or restrict content that engages in:
                </p>
              </div>
              <ul class="listing">
                <li>Copyright infringement</li>
                <li>Trademark infringement</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Violence and Criminal Behavior</p>
              <p class="RuleGuideLinesSecondryHeading">Threats</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  While we know that people sometimes express frustration or disagreement by threatening or calling for violence in non-serious ways,
                  we can’t risk the safety of our maritime community or the general public by ignoring threats of physical harm. We will attempt to
                  consider language and context when distinguishing casual comments from credible threats to public or personal safety, but Ath Marine
                  will also act quickly to block accounts and may notify law enforcement when threats appear to be credible.
                </p>
                <p class="RuleAndGuideParagraph">Do not:</p>
              </div>
              <ul class="listing">
                <li>Threaten violence against another maritime community member</li>
                <li>Offer or ask for services that threaten violence against individuals, groups, or the general public.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Glorification of violence</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Using Ath Marine to create content that glorifies, promotes or expresses support for acts of violence, is not acceptable on Ath
                  Marine.
                </p>
                <p class="RuleAndGuideParagraph">Please do not offer or ask for: orders that promote, support or glorify violence.</p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading"></p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph"></p>
              </div>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Incitement</p>
              <p class="RuleGuideLinesSecondryHeading"></p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  Incitement means encouraging or asking other people to engage in unlawful conduct. Be aware that inciting others to commit a crime
                  may be a crime in itself, regardless of the outcome of the request.
                </p>
                <p class="RuleAndGuideParagraph">Do not:</p>
              </div>
              <ul class="listing">
                <li>Ask for or instruct people to break the law.</li>
                <li>Engage in activities of questionable legality.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Terrorism</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  International and domestic terror organizations sometimes use the internet to create and spread propaganda designed to radicalize
                  vulnerable people and to develop materials designed to foster and aid terrorist attacks. Do not offer or ask for:
                </p>
              </div>
              <ul class="listing">
                <li>The promotion of terrorist activities.</li>
                <li>Services or products related to terror organizations or terrorists.</li>
                <li>Editing Content linked to terrorist acts.</li>
                <li>Recruitment to terror organizations.</li>
              </ul>
            </div>
            <div class="RuleGuideLinesContentContainer">
              <p class="RuleGuideLinesPrimaryHeading">Self-Harm</p>
              <div class="RuleAndGuideText">
                <p class="RuleAndGuideParagraph">
                  At Ath Marine, we care deeply about the safety of our maritime community. If someone you’re communicating with expresses suicidal
                  thoughts or is engaging in self-harm, please contact local authorities immediately and flag the content to us by writing to
                  <a href="mailto:marketing@athmarine.com">marketing@athmarine.com</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
