import React from "react";
import { Routes, Route } from "react-router-dom";

import { AuthProtected } from "./AuthProtected";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { PageNotFound } from "../pages/Static";

const Index = () => {
  const renderRoutes = (routes, isProtected = false) =>
    routes.map(({ path, component: Component }, idx) => (
      <Route
        path={path}
        element={
          isProtected ? (
            <AuthProtected>
              <Component />
            </AuthProtected>
          ) : (
            <Component />
          )
        }
        key={idx}
      />
    ));

  return (
    <Routes>
      {/* Public Routes */}
      {renderRoutes(publicRoutes)}

      {/* Auth Protected Routes */}
      {renderRoutes(authProtectedRoutes, true)}

      {/* Fallback for Page Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Index;
