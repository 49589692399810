import React from "react";
import { Navigate } from "react-router-dom";

import LocalStorageUtils from "../utils/storage/local-storage/local-storage-utils";
import LocalStorageConstants from "../utils/storage/local-storage/local-storage-constants";

export const AuthProtected = ({ children }) => {
  const token = LocalStorageUtils.getFromLocalStorage(
    LocalStorageConstants.AUTH_USER
  )?.token;

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};
