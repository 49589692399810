import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_EMPTY,
  DELETE_FILE,
} from "../../actionTypes";

const initialState = {
  data: [],
  message: null,
  loading: false,
  success: false,
  error: false,
};

const fileUpload = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...state,

        loading: true,
        success: false,
        error: false,
        message: null,
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action?.payload],
        loading: false,
        success: true,
        error: false,
        message: "File uploaded successfully.",
      };

    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };

    case UPLOAD_FILE_EMPTY:
      return {
        ...initialState,
      };

    case DELETE_FILE:
      return {
        ...state,
        data: state.data.filter((file) => file !== action.payload),
        message: "File deleted successfully.",
      };

    default:
      return state;
  }
};

export default fileUpload;
