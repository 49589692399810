// *********************************** MASTER - ACTION TYPES ***********************************

// GET COUNTRIES
export const GET_COUNTRIES = `GET_COUNTRIES`;
export const GET_COUNTRIES_SUCCESS = `GET_COUNTRIES_SUCCESS`;
export const GET_COUNTRIES_ERROR = `GET_COUNTRIES_ERROR`;
export const GET_COUNTRIES_EMPTY = `GET_COUNTRIES_EMPTY`;

// GET CURRENCIES
export const GET_CURRENCIES = `GET_CURRENCIES`;
export const GET_CURRENCIES_SUCCESS = `GET_CURRENCIES_SUCCESS`;
export const GET_CURRENCIES_ERROR = `GET_CURRENCIES_ERROR`;
export const GET_CURRENCIES_EMPTY = `GET_CURRENCIES_EMPTY`;

// GET STATES
export const GET_STATES = `GET_STATES`;
export const GET_STATES_SUCCESS = `GET_STATES_SUCCESS`;
export const GET_STATES_ERROR = `GET_STATES_ERROR`;
export const GET_STATES_EMPTY = `GET_STATES_EMPTY`;

// GET CITIES
export const GET_CITIES = `GET_CITIES`;
export const GET_CITIES_SUCCESS = `GET_CITIES_SUCCESS`;
export const GET_CITIES_ERROR = `GET_CITIES_ERROR`;
export const GET_CITIES_EMPTY = `GET_CITIES_EMPTY`;

// GET PORTS
export const GET_PORTS = `GET_PORTS`;
export const GET_PORTS_SUCCESS = `GET_PORTS_SUCCESS`;
export const GET_PORTS_ERROR = `GET_PORTS_ERROR`;
export const GET_PORTS_EMPTY = `GET_PORTS_EMPTY`;

// GET SOCIAL MEDIAS
export const GET_SOCIAL_MEDIAS = `GET_SOCIAL_MEDIAS`;
export const GET_SOCIAL_MEDIAS_SUCCESS = `GET_SOCIAL_MEDIAS_SUCCESS`;
export const GET_SOCIAL_MEDIAS_ERROR = `GET_SOCIAL_MEDIAS_ERROR`;
export const GET_SOCIAL_MEDIAS_EMPTY = `GET_SOCIAL_MEDIAS_EMPTY`;

// GET CATEGORIES
export const GET_CATEGORIES = `GET_CATEGORIES`;
export const GET_CATEGORIES_SUCCESS = `GET_CATEGORIES_SUCCESS`;
export const GET_CATEGORIES_ERROR = `GET_CATEGORIES_ERROR`;
export const GET_CATEGORIES_EMPTY = `GET_CATEGORIES_EMPTY`;

// GET EQUIPMENTS
export const GET_EQUIPMENTS = `GET_EQUIPMENTS`;
export const GET_EQUIPMENTS_SUCCESS = `GET_EQUIPMENTS_SUCCESS`;
export const GET_EQUIPMENTS_ERROR = `GET_EQUIPMENTS_ERROR`;
export const GET_EQUIPMENTS_EMPTY = `GET_EQUIPMENTS_EMPTY`;

// GET MAKES
export const GET_MAKES = `GET_MAKES`;
export const GET_MAKES_SUCCESS = `GET_MAKES_SUCCESS`;
export const GET_MAKES_ERROR = `GET_MAKES_ERROR`;
export const GET_MAKES_EMPTY = `GET_MAKES_EMPTY`;

// GET VESSEL CATEGORIES
export const GET_VESSEL_CATEGORIES = `GET_VESSEL_CATEGORIES`;
export const GET_VESSEL_CATEGORIES_SUCCESS = `GET_VESSEL_CATEGORIES_SUCCESS`;
export const GET_VESSEL_CATEGORIES_ERROR = `GET_VESSEL_CATEGORIES_ERROR`;
export const GET_VESSEL_CATEGORIES_EMPTY = `GET_VESSEL_CATEGORIES_EMPTY`;

// GET ISSUE TYPES
export const GET_ISSUE_TYPES = `GET_ISSUE_TYPES`;
export const GET_ISSUE_TYPES_SUCCESS = `GET_ISSUE_TYPES_SUCCESS`;
export const GET_ISSUE_TYPES_ERROR = `GET_ISSUE_TYPES_ERROR`;
export const GET_ISSUE_TYPES_EMPTY = `GET_ISSUE_TYPES_EMPTY`;

// GET PORT LOCATIONS
export const GET_PORT_LOCATIONS = `GET_PORT_LOCATIONS`;
export const GET_PORT_LOCATIONS_SUCCESS = `GET_PORT_LOCATIONS_SUCCESS`;
export const GET_PORT_LOCATIONS_ERROR = `GET_PORT_LOCATIONS_ERROR`;
export const GET_PORT_LOCATIONS_EMPTY = `GET_PORT_LOCATIONS_EMPTY`;

// GET VESSEL TYPES
export const GET_VESSEL_TYPES = `GET_VESSEL_TYPES`;
export const GET_VESSEL_TYPES_SUCCESS = `GET_VESSEL_TYPES_SUCCESS`;
export const GET_VESSEL_TYPES_ERROR = `GET_VESSEL_TYPES_ERROR`;
export const GET_VESSEL_TYPES_EMPTY = `GET_VESSEL_TYPES_EMPTY`;

// GET VESSEL FLAGS
export const GET_VESSEL_FLAGS = `GET_VESSEL_FLAGS`;
export const GET_VESSEL_FLAGS_SUCCESS = `GET_VESSEL_FLAGS_SUCCESS`;
export const GET_VESSEL_FLAGS_ERROR = `GET_VESSEL_FLAGS_ERROR`;
export const GET_VESSEL_FLAGS_EMPTY = `GET_VESSEL_FLAGS_EMPTY`;

// *********************************** REGISTRATION - ACTION TYPES ***********************************

// VENDOR REGISTRATION
export const VENDOR_REGISTRATION = `VENDOR_REGISTRATION`;
export const VENDOR_REGISTRATION_SUCCESS = `VENDOR_REGISTRATION_SUCCESS`;
export const VENDOR_REGISTRATION_ERROR = `VENDOR_REGISTRATION_ERROR`;
export const VENDOR_REGISTRATION_EMPTY = `VENDOR_REGISTRATION_EMPTY`;

// CUSTOMER REGISTRATION
export const CUSTOMER_REGISTRATION = `CUSTOMER_REGISTRATION`;
export const CUSTOMER_REGISTRATION_SUCCESS = `CUSTOMER_REGISTRATION_SUCCESS`;
export const CUSTOMER_REGISTRATION_ERROR = `CUSTOMER_REGISTRATION_ERROR`;
export const CUSTOMER_REGISTRATION_EMPTY = `CUSTOMER_REGISTRATION_EMPTY`;

// *********************************** USER AUTHENTICATION - ACTION TYPES ***********************************

// LOGIN USER
export const LOGIN_USER = `LOGIN_USER`;
export const LOGIN_USER_SUCCESS = `LOGIN_USER_SUCCESS`;
export const LOGIN_USER_ERROR = `LOGIN_USER_ERROR`;
export const LOGIN_USER_EMPTY = `LOGIN_USER_EMPTY`;

// SEND RESET PASSWORD LINK
export const SEND_RESET_PASSWORD_LINK = `SEND_RESET_PASSWORD_LINK`;
export const SEND_RESET_PASSWORD_LINK_SUCCESS = `SEND_RESET_PASSWORD_LINK_SUCCESS`;
export const SEND_RESET_PASSWORD_LINK_ERROR = `SEND_RESET_PASSWORD_LINK_ERROR`;
export const SEND_RESET_PASSWORD_LINK_EMPTY = `SEND_RESET_PASSWORD_LINK_EMPTY`;

// RESEND OTP
export const RESEND_OTP = `RESEND_OTP`;
export const RESEND_OTP_SUCCESS = `RESEND_OTP_SUCCESS`;
export const RESEND_OTP_ERROR = `RESEND_OTP_ERROR`;
export const RESEND_OTP_EMPTY = `RESEND_OTP_EMPTY`;

// RESEND VERIFICATION LINK
export const RESEND_VERIFICATION_LINK = `RESEND_VERIFICATION_LINK`;
export const RESEND_VERIFICATION_LINK_SUCCESS = `RESEND_VERIFICATION_LINK_SUCCESS`;
export const RESEND_VERIFICATION_LINK_ERROR = `RESEND_VERIFICATION_LINK_ERROR`;
export const RESEND_VERIFICATION_LINK_EMPTY = `RESEND_VERIFICATION_LINK_EMPTY`;

// VERIFY OTP
export const VERIFY_OTP = `VERIFY_OTP`;
export const VERIFY_OTP_SUCCESS = `VERIFY_OTP_SUCCESS`;
export const VERIFY_OTP_ERROR = `VERIFY_OTP_ERROR`;
export const VERIFY_OTP_EMPTY = `VERIFY_OTP_EMPTY`;

// RESET PASSWORD
export const RESET_PASSWORD = `RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_ERROR = `RESET_PASSWORD_ERROR`;
export const RESET_PASSWORD_EMPTY = `RESET_PASSWORD_EMPTY`;

// UPDATE PASSWORD
export const UPDATE_PASSWORD = `UPDATE_PASSWORD`;
export const UPDATE_PASSWORD_SUCCESS = `UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_ERROR = `UPDATE_PASSWORD_ERROR`;
export const UPDATE_PASSWORD_EMPTY = `UPDATE_PASSWORD_EMPTY`;

// VERIFY EMAIL
export const VERIFY_EMAIL = `VERIFY_EMAIL`;
export const VERIFY_EMAIL_SUCCESS = `VERIFY_EMAIL_SUCCESS`;
export const VERIFY_EMAIL_ERROR = `VERIFY_EMAIL_ERROR`;
export const VERIFY_EMAIL_EMPTY = `VERIFY_EMAIL_EMPTY`;

// *********************************** VENDOR REGISTRATION - ACTION TYPES ***********************************

// GET VENDOR ADDRESS
export const GET_VENDOR_ADDRESS = `GET_VENDOR_ADDRESS`;
export const GET_VENDOR_ADDRESS_SUCCESS = `GET_VENDOR_ADDRESS_SUCCESS`;
export const GET_VENDOR_ADDRESS_ERROR = `GET_VENDOR_ADDRESS_ERROR`;
export const GET_VENDOR_ADDRESS_EMPTY = `GET_VENDOR_ADDRESS_EMPTY`;

// CREATE VENDOR ADDRESS
export const CREATE_VENDOR_ADDRESS = `CREATE_VENDOR_ADDRESS`;
export const CREATE_VENDOR_ADDRESS_SUCCESS = `CREATE_VENDOR_ADDRESS_SUCCESS`;
export const CREATE_VENDOR_ADDRESS_ERROR = `CREATE_VENDOR_ADDRESS_ERROR`;
export const CREATE_VENDOR_ADDRESS_EMPTY = `CREATE_VENDOR_ADDRESS_EMPTY`;

// GET VENDOR HEAD
export const GET_VENDOR_HEAD = `GET_VENDOR_HEAD`;
export const GET_VENDOR_HEAD_SUCCESS = `GET_VENDOR_HEAD_SUCCESS`;
export const GET_VENDOR_HEAD_ERROR = `GET_VENDOR_HEAD_ERROR`;
export const GET_VENDOR_HEAD_EMPTY = `GET_VENDOR_HEAD_EMPTY`;

// CREATE VENDOR HEAD
export const CREATE_VENDOR_HEAD = `CREATE_VENDOR_HEAD`;
export const CREATE_VENDOR_HEAD_SUCCESS = `CREATE_VENDOR_HEAD_SUCCESS`;
export const CREATE_VENDOR_HEAD_ERROR = `CREATE_VENDOR_HEAD_ERROR`;
export const CREATE_VENDOR_HEAD_EMPTY = `CREATE_VENDOR_HEAD_EMPTY`;

// GET VENDOR BUSINESS INFO
export const GET_VENDOR_BUSINESS_INFO = `GET_VENDOR_BUSINESS_INFO`;
export const GET_VENDOR_BUSINESS_INFO_SUCCESS = `GET_VENDOR_BUSINESS_INFO_SUCCESS`;
export const GET_VENDOR_BUSINESS_INFO_ERROR = `GET_VENDOR_BUSINESS_INFO_ERROR`;
export const GET_VENDOR_BUSINESS_INFO_EMPTY = `GET_VENDOR_BUSINESS_INFO_EMPTY`;

// CREATE VENDOR BUSINESS INFO
export const CREATE_VENDOR_BUSINESS_INFO = `CREATE_VENDOR_BUSINESS_INFO`;
export const CREATE_VENDOR_BUSINESS_INFO_SUCCESS = `CREATE_VENDOR_BUSINESS_INFO_SUCCESS`;
export const CREATE_VENDOR_BUSINESS_INFO_ERROR = `CREATE_VENDOR_BUSINESS_INFO_ERROR`;
export const CREATE_VENDOR_BUSINESS_INFO_EMPTY = `CREATE_VENDOR_BUSINESS_INFO_EMPTY`;

// GET BIDDER
export const GET_BIDDERS = `GET_BIDDERS`;
export const GET_BIDDERS_SUCCESS = `GET_BIDDERS_SUCCESS`;
export const GET_BIDDERS_ERROR = `GET_BIDDERS_ERROR`;
export const GET_BIDDERS_EMPTY = `GET_BIDDERS_EMPTY`;

// CREATE BIDDER
export const CREATE_BIDDER = `CREATE_BIDDER`;
export const CREATE_BIDDER_SUCCESS = `CREATE_BIDDER_SUCCESS`;
export const CREATE_BIDDER_ERROR = `CREATE_BIDDER_ERROR`;
export const CREATE_BIDDER_EMPTY = `CREATE_BIDDER_EMPTY`;

// GET APPROVER
export const GET_APPROVERS = `GET_APPROVERS`;
export const GET_APPROVERS_SUCCESS = `GET_APPROVERS_SUCCESS`;
export const GET_APPROVERS_ERROR = `GET_APPROVERS_ERROR`;
export const GET_APPROVERS_EMPTY = `GET_APPROVERS_EMPTY`;

// CREATE APPROVER
export const CREATE_APPROVER = `CREATE_APPROVER`;
export const CREATE_APPROVER_SUCCESS = `CREATE_APPROVER_SUCCESS`;
export const CREATE_APPROVER_ERROR = `CREATE_APPROVER_ERROR`;
export const CREATE_APPROVER_EMPTY = `CREATE_APPROVER_EMPTY`;

// GET ENGINEER
export const GET_ENGINEERS = `GET_ENGINEERS`;
export const GET_ENGINEERS_SUCCESS = `GET_ENGINEERS_SUCCESS`;
export const GET_ENGINEERS_ERROR = `GET_ENGINEERS_ERROR`;
export const GET_ENGINEERS_EMPTY = `GET_ENGINEERS_EMPTY`;

// CREATE ENGINEER
export const CREATE_ENGINEER = `CREATE_ENGINEER`;
export const CREATE_ENGINEER_SUCCESS = `CREATE_ENGINEER_SUCCESS`;
export const CREATE_ENGINEER_ERROR = `CREATE_ENGINEER_ERROR`;
export const CREATE_ENGINEER_EMPTY = `CREATE_ENGINEER_EMPTY`;

// GET SERVICE
export const GET_SERVICES = `GET_SERVICES`;
export const GET_SERVICES_SUCCESS = `GET_SERVICES_SUCCESS`;
export const GET_SERVICES_ERROR = `GET_SERVICES_ERROR`;
export const GET_SERVICES_EMPTY = `GET_SERVICES_EMPTY`;

// CREATE SERVICE
export const CREATE_SERVICE = `CREATE_SERVICE`;
export const CREATE_SERVICE_SUCCESS = `CREATE_SERVICE_SUCCESS`;
export const CREATE_SERVICE_ERROR = `CREATE_SERVICE_ERROR`;
export const CREATE_SERVICE_EMPTY = `CREATE_SERVICE_EMPTY`;

// GET VENDOR FINANCIER
export const GET_VENDOR_FINANCIERS = `GET_VENDOR_FINANCIERS`;
export const GET_VENDOR_FINANCIERS_SUCCESS = `GET_VENDOR_FINANCIERS_SUCCESS`;
export const GET_VENDOR_FINANCIERS_ERROR = `GET_VENDOR_FINANCIERS_ERROR`;
export const GET_VENDOR_FINANCIERS_EMPTY = `GET_VENDOR_FINANCIERS_EMPTY`;

// CREATE VENDOR FINANCIER
export const CREATE_VENDOR_FINANCIER = `CREATE_VENDOR_FINANCIER`;
export const CREATE_VENDOR_FINANCIER_SUCCESS = `CREATE_VENDOR_FINANCIER_SUCCESS`;
export const CREATE_VENDOR_FINANCIER_ERROR = `CREATE_VENDOR_FINANCIER_ERROR`;
export const CREATE_VENDOR_FINANCIER_EMPTY = `CREATE_VENDOR_FINANCIER_EMPTY`;

// GET ENGINEERS CHARGES
export const GET_ENGINEERS_CHARGES = `GET_ENGINEERS_CHARGES`;
export const GET_ENGINEERS_CHARGES_SUCCESS = `GET_ENGINEERS_CHARGES_SUCCESS`;
export const GET_ENGINEERS_CHARGES_ERROR = `GET_ENGINEERS_CHARGES_ERROR`;
export const GET_ENGINEERS_CHARGES_EMPTY = `GET_ENGINEERS_CHARGES_EMPTY`;

// MARK REGISTRATION COMPLETED
export const MARK_REGISTRATION_COMPLETED = `MARK_REGISTRATION_COMPLETED`;
export const MARK_REGISTRATION_COMPLETED_SUCCESS = `MARK_REGISTRATION_COMPLETED_SUCCESS`;
export const MARK_REGISTRATION_COMPLETED_ERROR = `MARK_REGISTRATION_COMPLETED_ERROR`;
export const MARK_REGISTRATION_COMPLETED_EMPTY = `MARK_REGISTRATION_COMPLETED_EMPTY`;

// GET INVOICE DETAILS
export const GET_INVOICE_DETAILS = `GET_INVOICE_DETAILS`;
export const GET_INVOICE_DETAILS_SUCCESS = `GET_INVOICE_DETAILS_SUCCESS`;
export const GET_INVOICE_DETAILS_ERROR = `GET_INVOICE_DETAILS_ERROR`;
export const GET_INVOICE_DETAILS_EMPTY = `GET_INVOICE_DETAILS_EMPTY`;

// DOWNLOAD INVOICE
export const DOWNLOAD_INVOICE = `DOWNLOAD_INVOICE`;
export const DOWNLOAD_INVOICE_SUCCESS = `DOWNLOAD_INVOICE_SUCCESS`;
export const DOWNLOAD_INVOICE_ERROR = `DOWNLOAD_INVOICE_ERROR`;
export const DOWNLOAD_INVOICE_EMPTY = `DOWNLOAD_INVOICE_EMPTY`;

// SEND INVOICE
export const SEND_INVOICE = `SEND_INVOICE`;
export const SEND_INVOICE_SUCCESS = `SEND_INVOICE_SUCCESS`;
export const SEND_INVOICE_ERROR = `SEND_INVOICE_ERROR`;
export const SEND_INVOICE_EMPTY = `SEND_INVOICE_EMPTY`;

// FILE UPLOAD
export const FILE_UPLOAD = `FILE_UPLOAD`;
export const FILE_UPLOAD_SUCCESS = `FILE_UPLOAD_SUCCESS`;
export const FILE_UPLOAD_ERROR = `FILE_UPLOAD_ERROR`;
export const FILE_UPLOAD_EMPTY = `FILE_UPLOAD_EMPTY`;

// *********************************** CUSTOMER REGISTRATION - ACTION TYPES ***********************************

// GET CUSTOMER ADDRESS
export const GET_CUSTOMER_ADDRESS = `GET_CUSTOMER_ADDRESS`;
export const GET_CUSTOMER_ADDRESS_SUCCESS = `GET_CUSTOMER_ADDRESS_SUCCESS`;
export const GET_CUSTOMER_ADDRESS_ERROR = `GET_CUSTOMER_ADDRESS_ERROR`;
export const GET_CUSTOMER_ADDRESS_EMPTY = `GET_CUSTOMER_ADDRESS_EMPTY`;

// CREATE CUSTOMER_ADDRESS
export const CREATE_CUSTOMER_ADDRESS = `CREATE_CUSTOMER_ADDRESS`;
export const CREATE_CUSTOMER_ADDRESS_SUCCESS = `CREATE_CUSTOMER_ADDRESS_SUCCESS`;
export const CREATE_CUSTOMER_ADDRESS_ERROR = `CREATE_CUSTOMER_ADDRESS_ERROR`;
export const CREATE_CUSTOMER_ADDRESS_EMPTY = `CREATE_CUSTOMER_ADDRESS_EMPTY`;

// GET CUSTOMER HEAD
export const GET_CUSTOMER_HEAD = `GET_CUSTOMER_HEAD`;
export const GET_CUSTOMER_HEAD_SUCCESS = `GET_CUSTOMER_HEAD_SUCCESS`;
export const GET_CUSTOMER_HEAD_ERROR = `GET_CUSTOMER_HEAD_ERROR`;
export const GET_CUSTOMER_HEAD_EMPTY = `GET_CUSTOMER_HEAD_EMPTY`;

// CREATE CUSTOMER HEAD
export const CREATE_CUSTOMER_HEAD = `CREATE_CUSTOMER_HEAD`;
export const CREATE_CUSTOMER_HEAD_SUCCESS = `CREATE_CUSTOMER_HEAD_SUCCESS`;
export const CREATE_CUSTOMER_HEAD_ERROR = `CREATE_CUSTOMER_HEAD_ERROR`;
export const CREATE_CUSTOMER_HEAD_EMPTY = `CREATE_CUSTOMER_HEAD_EMPTY`;

// GET CUSTOMER BUSINESS INFO
export const GET_CUSTOMER_BUSINESS_INFO = `GET_CUSTOMER_BUSINESS_INFO`;
export const GET_CUSTOMER_BUSINESS_INFO_SUCCESS = `GET_CUSTOMER_BUSINESS_INFO_SUCCESS`;
export const GET_CUSTOMER_BUSINESS_INFO_ERROR = `GET_CUSTOMER_BUSINESS_INFO_ERROR`;
export const GET_CUSTOMER_BUSINESS_INFO_EMPTY = `GET_CUSTOMER_BUSINESS_INFO_EMPTY`;

// CREATE CUSTOMER BUSINESS INFO
export const CREATE_CUSTOMER_BUSINESS_INFO = `CREATE_CUSTOMER_BUSINESS_INFO`;
export const CREATE_CUSTOMER_BUSINESS_INFO_SUCCESS = `CREATE_CUSTOMER_BUSINESS_INFO_SUCCESS`;
export const CREATE_CUSTOMER_BUSINESS_INFO_ERROR = `CREATE_CUSTOMER_BUSINESS_INFO_ERROR`;
export const CREATE_CUSTOMER_BUSINESS_INFO_EMPTY = `CREATE_CUSTOMER_BUSINESS_INFO_EMPTY`;

// GET CUSTOMER FINANCIER
export const GET_CUSTOMER_FINANCIER = `GET_CUSTOMER_FINANCIER`;
export const GET_CUSTOMER_FINANCIER_SUCCESS = `GET_CUSTOMER_FINANCIER_SUCCESS`;
export const GET_CUSTOMER_FINANCIER_ERROR = `GET_CUSTOMER_FINANCIER_ERROR`;
export const GET_CUSTOMER_FINANCIER_EMPTY = `GET_CUSTOMER_FINANCIER_EMPTY`;

// CREATE CUSTOMER FINANCIER
export const CREATE_CUSTOMER_FINANCIER = `CREATE_CUSTOMER_FINANCIER`;
export const CREATE_CUSTOMER_FINANCIER_SUCCESS = `CREATE_CUSTOMER_FINANCIER_SUCCESS`;
export const CREATE_CUSTOMER_FINANCIER_ERROR = `CREATE_CUSTOMER_FINANCIER_ERROR`;
export const CREATE_CUSTOMER_FINANCIER_EMPTY = `CREATE_CUSTOMER_FINANCIER_EMPTY`;

// GET FLEET INFO
export const GET_FLEET_INFO = `GET_FLEET_INFO`;
export const GET_FLEET_INFO_SUCCESS = `GET_FLEET_INFO_SUCCESS`;
export const GET_FLEET_INFO_ERROR = `GET_FLEET_INFO_ERROR`;
export const GET_FLEET_INFO_EMPTY = `GET_FLEET_INFO_EMPTY`;

// CREATE FLEET INFO
export const CREATE_FLEET_INFO = `CREATE_FLEET_INFO`;
export const CREATE_FLEET_INFO_SUCCESS = `CREATE_FLEET_INFO_SUCCESS`;
export const CREATE_FLEET_INFO_ERROR = `CREATE_FLEET_INFO_ERROR`;
export const CREATE_FLEET_INFO_EMPTY = `CREATE_FLEET_INFO_EMPTY`;

// *********************************** SUPER ADMIN - ACTION TYPES ***********************************

// GET ACTIVE VENDOR COUNT
export const GET_ACTIVE_VENDOR_COUNT = `GET_ACTIVE_VENDOR_COUNT`;
export const GET_ACTIVE_VENDOR_COUNT_SUCCESS = `GET_ACTIVE_VENDOR_COUNT_SUCCESS`;
export const GET_ACTIVE_VENDOR_COUNT_ERROR = `GET_ACTIVE_VENDOR_COUNT_ERROR`;
export const GET_ACTIVE_VENDOR_COUNT_EMPTY = `GET_ACTIVE_VENDOR_COUNT_EMPTY`;

// GET ACTIVE CUSTOMER COUNT
export const GET_ACTIVE_CUSTOMER_COUNT = `GET_ACTIVE_CUSTOMER_COUNT`;
export const GET_ACTIVE_CUSTOMER_COUNT_SUCCESS = `GET_ACTIVE_CUSTOMER_COUNT_SUCCESS`;
export const GET_ACTIVE_CUSTOMER_COUNT_ERROR = `GET_ACTIVE_CUSTOMER_COUNT_ERROR`;
export const GET_ACTIVE_CUSTOMER_COUNT_EMPTY = `GET_ACTIVE_CUSTOMER_COUNT_EMPTY`;

// GET VENDORS
export const GET_VENDORS = `GET_VENDORS`;
export const GET_VENDORS_SUCCESS = `GET_VENDORS_SUCCESS`;
export const GET_VENDORS_ERROR = `GET_VENDORS_ERROR`;
export const GET_VENDORS_EMPTY = `GET_VENDORS_EMPTY`;

// GET CUSTOMERS
export const GET_CUSTOMERS = `GET_CUSTOMERS`;
export const GET_CUSTOMERS_SUCCESS = `GET_CUSTOMERS_SUCCESS`;
export const GET_CUSTOMERS_ERROR = `GET_CUSTOMERS_ERROR`;
export const GET_CUSTOMERS_EMPTY = `GET_CUSTOMERS_EMPTY`;

// GET PROMOTIONS
export const GET_PROMOTIONS = `GET_PROMOTIONS`;
export const GET_PROMOTIONS_SUCCESS = `GET_PROMOTIONS_SUCCESS`;
export const GET_PROMOTIONS_ERROR = `GET_PROMOTIONS_ERROR`;
export const GET_PROMOTIONS_EMPTY = `GET_PROMOTIONS_EMPTY`;

// UPDATE PROMOTIONS
export const UPDATE_PROMOTIONS = `UPDATE_PROMOTIONS`;
export const UPDATE_PROMOTIONS_SUCCESS = `UPDATE_PROMOTIONS_SUCCESS`;
export const UPDATE_PROMOTIONS_ERROR = `UPDATE_PROMOTIONS_ERROR`;
export const UPDATE_PROMOTIONS_EMPTY = `UPDATE_PROMOTIONS_EMPTY`;

// *********************************** TEMPORARY UPDATE USER - ACTION TYPES ***********************************

// GET USER
export const GET_USER = `GET_USER`;
export const GET_USER_SUCCESS = `GET_USER_SUCCESS`;
export const GET_USER_ERROR = `GET_USER_ERROR`;
export const GET_USER_EMPTY = `GET_USER_EMPTY`;

// UPDATE USER
export const UPDATE_USER = `UPDATE_USER`;
export const UPDATE_USER_SUCCESS = `UPDATE_USER_SUCCESS`;
export const UPDATE_USER_ERROR = `UPDATE_USER_ERROR`;
export const UPDATE_USER_EMPTY = `UPDATE_USER_EMPTY`;

// *********************************** VENDOR DASHBOARD - ACTION TYPES ***********************************
// GET DASHBOARD DATA
export const GET_DASHBOARD_DATA = `GET_DASHBOARD_DATA`;
export const GET_DASHBOARD_DATA_SUCCESS = `GET_DASHBOARD_DATA_SUCCESS`;
export const GET_DASHBOARD_DATA_ERROR = `GET_DASHBOARD_DATA_ERROR`;
export const GET_DASHBOARD_DATA_EMPTY = `GET_DASHBOARD_DATA_EMPTY`;

// CREATE SERVICE REQUEST
export const CREATE_SERVICE_REQUEST = `CREATE_SERVICE_REQUEST`;
export const CREATE_SERVICE_REQUEST_SUCCESS = `CREATE_SERVICE_REQUEST_SUCCESS`;
export const CREATE_SERVICE_REQUEST_ERROR = `CREATE_SERVICE_REQUEST_ERROR`;
export const CREATE_SERVICE_REQUEST_EMPTY = `CREATE_SERVICE_REQUEST_EMPTY`;

// GET SERVICE REQUESTS
export const GET_SERVICE_REQUESTS = `GET_SERVICE_REQUESTS`;
export const GET_SERVICE_REQUESTS_SUCCESS = `GET_SERVICE_REQUESTS_SUCCESS`;
export const GET_SERVICE_REQUESTS_ERROR = `GET_SERVICE_REQUESTS_ERROR`;
export const GET_SERVICE_REQUESTS_EMPTY = `GET_SERVICE_REQUESTS_EMPTY`;

// GET SERVICE REQUEST DETAIL
export const GET_SERVICE_REQUEST_DETAIL = "GET_SERVICE_REQUEST_DETAIL";
export const GET_SERVICE_REQUEST_DETAIL_SUCCESS =
  "GET_SERVICE_REQUEST_DETAIL_SUCCESS";
export const GET_SERVICE_REQUEST_DETAIL_ERROR =
  "GET_SERVICE_REQUEST_DETAIL_ERROR";
export const GET_SERVICE_REQUEST_DETAIL_EMPTY =
  "GET_SERVICE_REQUEST_DETAIL_EMPTY";
// GET SERVICE REQUEST BY ID
export const GET_SERVICE_REQUEST_BY_ID = `GET_SERVICE_REQUEST_BY_ID`;
export const GET_SERVICE_REQUEST_BY_ID_SUCCESS = `GET_SERVICE_REQUEST_BY_ID_SUCCESS`;
export const GET_SERVICE_REQUEST_BY_ID_ERROR = `GET_SERVICE_REQUEST_BY_ID_ERROR`;
export const GET_SERVICE_REQUEST_BY_ID_EMPTY = `GET_SERVICE_REQUEST_BY_ID_EMPTY`;

// UPDATE SERVICE REQUEST
export const UPDATE_SERVICE_REQUEST = `UPDATE_SERVICE_REQUEST`;
export const UPDATE_SERVICE_REQUEST_SUCCESS = `UPDATE_SERVICE_REQUEST_SUCCESS`;
export const UPDATE_SERVICE_REQUEST_ERROR = `UPDATE_SERVICE_REQUEST_ERROR`;
export const UPDATE_SERVICE_REQUEST_EMPTY = `UPDATE_SERVICE_REQUEST_EMPTY`;

// UPLOAD File
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const UPLOAD_FILE_EMPTY = "UPLOAD_FILE_EMPTY";
export const DELETE_FILE = "DELETE_FILE";

// GET USER DASHBOARD
export const GET_USER_DASHBOARD = `GET_USER_DASHBOARD`;
export const GET_USER_DASHBOARD_SUCCESS = `GET_USER_DASHBOARD_SUCCESS`;
export const GET_USER_DASHBOARD_ERROR = `GET_USER_DASHBOARD_ERROR`;
export const GET_USER_DASHBOARD_EMPTY = `GET_USER_DASHBOARD_EMPTY`;

// updating service request status
export const UPDATE_SERVICE_REQUEST_STATUS = "UPDATE_SERVICE_REQUEST_STATUS";
export const UPDATE_SERVICE_REQUEST_STATUS_SUCCESS =
  "UPDATE_SERVICE_REQUEST_STATUS_SUCCESS";
export const UPDATE_SERVICE_REQUEST_STATUS_ERROR =
  "UPDATE_SERVICE_REQUEST_STATUS_ERROR";
export const UPDATE_SERVICE_REQUEST_STATUS_EMPTY =
  "UPDATE_SERVICE_REQUEST_STATUS_EMPTY";

// LOGIN
export const VENDOR_LOGIN = `VENDOR_LOGIN`;
export const VENDOR_LOGIN_SUCCESS = `VENDOR_LOGIN_SUCCESS`;
export const VENDOR_LOGIN_ERROR = `VENDOR_LOGIN_ERROR`;
export const VENDOR_LOGIN_EMPTY = `VENDOR_LOGIN_EMPTY`;

// *************** BIDDER ***************
// GET ALL BIDS
export const GET_ALL_BIDS = `GET_ALL_BIDS`;
export const GET_ALL_BIDS_SUCCESS = `GET_ALL_BIDS_SUCCESS`;
export const GET_ALL_BIDS_ERROR = `GET_ALL_BIDS_ERROR`;
export const GET_ALL_BIDS_EMPTY = `GET_ALL_BIDS_EMPTY`;

// GET BID DETAIL BY ID
export const GET_BID_DETAIL_BY_ID = `GET_BID_DETAIL_BY_ID`;
export const GET_BID_DETAIL_BY_ID_SUCCESS = `GET_BID_DETAIL_BY_ID_SUCCESS`;
export const GET_BID_DETAIL_BY_ID_ERROR = `GET_BID_DETAIL_BY_ID_ERROR`;
export const GET_BID_DETAIL_BY_ID_EMPTY = `GET_BID_DETAIL_BY_ID_EMPTY`;

// MARK INTERESTED
export const MARK_INTERESTED = `MARK_INTERESTED`;
export const MARK_INTERESTED_SUCCESS = `MARK_INTERESTED_SUCCESS`;
export const MARK_INTERESTED_ERROR = `MARK_INTERESTED_ERROR`;
export const MARK_INTERESTED_EMPTY = `MARK_INTERESTED_EMPTY`;

// UPDATE BID STATUS
export const UPDATE_BID_STATUS = `UPDATE_BID_STATUS`;
export const UPDATE_BID_STATUS_SUCCESS = `UPDATE_BID_STATUS_SUCCESS`;
export const UPDATE_BID_STATUS_ERROR = `UPDATE_BID_STATUS_ERROR`;
export const UPDATE_BID_STATUS_EMPTY = `UPDATE_BID_STATUS_EMPTY`;

// *********************************** CUSTOMER DASHBOARD - ACTION TYPES ***********************************
// *************** REQUESTOR ***************
