import { RiFilter2Line } from "react-icons/ri";
import { GrRefresh } from "react-icons/gr";

const FilterRefresh = ({ onRefresh, onFilter }) => {
  return (
    <div className="fiter_container">
      <div className="refresh" onClick={onRefresh}>
        <GrRefresh />
      </div>
      <div className="refresh">
        <RiFilter2Line onClick={onFilter} />
      </div>
    </div>
  );
};
export default FilterRefresh;
