import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  GET_SERVICE_REQUEST_BY_ID,
  GET_SERVICE_REQUEST_BY_ID_SUCCESS,
  GET_SERVICE_REQUEST_BY_ID_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* getServiceRequestById() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/service/v1/request/detail`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_SERVICE_REQUEST_BY_ID_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: GET_SERVICE_REQUEST_BY_ID_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_SERVICE_REQUEST_BY_ID_ERROR, payload: error });
  }
}

export function* watchGetServiceRequestById() {
  yield takeEvery(GET_SERVICE_REQUEST_BY_ID, getServiceRequestById);
}

function* getServiceRequestByIdSaga() {
  yield all([fork(watchGetServiceRequestById)]);
}

export default getServiceRequestByIdSaga;
