import { Footer, Header } from '../../../components/common';

export default function About() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />
      <main className="section_about_us">
        <section className="about_us_container">
          <h1 className="quote">&#34;The greater the obstacle, the more glory in overcoming it.&#34;</h1>

          <div className="aboutBody">
            <h1 className="aboutHeading">Our Story</h1>

            <div className="body">
              <p className="aboutPara">
                We are a unique group of experienced professionals from the maritime and technology sectors with a combined experience of more than
                135 years. As a team we have among us senior executive level experience with ship owners, ship managers, original equipment
                manufacturers and pure technology providers. We are enthusiastic about using our combined knowledge about various aspects of the
                maritime industry and the technology space to make a positive impact on some aspects of the working of our beloved industry.
              </p>

              <p className="aboutPara">
                We believe there exists a great disparity between a service consumer and a service provider and bridging this gap would bringin
                greater transparencies resulting in greater efficiencies. This solution originates out of this belief and is accelerated with a strong
                intention to uphold fair service practices, eliminate unfair and illegitimate deals that arise due to lack of information or lack of
                right attitude, in some cases.
              </p>

              <p className="aboutPara">
                This is our first, but firm step, towards making our industry dealings more transparent, more efficient and even more equalization of
                fair opportunities and benefits.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
