import { Col, Container, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../components/constants';
import error400cover from './../../../assets/images/error400-cover.png';

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="auth-page-content">
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden p-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl={7} lg={8}>
                  <div className="text-center">
                    <img src={error400cover} alt="error img" className="img-fluid" />
                    <div className="mt-3">
                      <h3 className="text-uppercase">Sorry, Page not Found</h3>

                      <p className="text-muted mb-4">The page you are looking for not available!</p>
                      <div to="/dashboard" className="btn btn-success" onClick={() => navigate(AppRoutes?.OUR_SOLUTIONS)}>
                        <i className="mdi mdi-home me-1"></i>Back to home
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
