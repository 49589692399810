import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import HttpClient from "../../../../config/http-client.js";
import { authToken, baseUrl, headers } from "../../../../config/index.js";
import {
  GET_USER_DASHBOARD,
  GET_USER_DASHBOARD_ERROR,
  GET_USER_DASHBOARD_SUCCESS,
} from "../../actionTypes.js";

function* getUserDashboard() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/user/v1/dashboard`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {},
    });

    if (response?.status === 200) {
      yield put({
        type: GET_USER_DASHBOARD_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_USER_DASHBOARD_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_DASHBOARD_ERROR, payload: error });
  }
}

export function* watchGetUserDashboard() {
  yield takeEvery(GET_USER_DASHBOARD, getUserDashboard);
}

function* getUserDashboardSaga() {
  yield all([fork(watchGetUserDashboard)]);
}

export default getUserDashboardSaga;
