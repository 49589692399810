import { ModalBody } from "reactstrap";
import { ModalLayout } from "../../../../../components/common";
import SuccessGif from "./../../../../../assets/images/dashboard/success.gif";

function RequestPlacedSuccessfulModal() {
  return (
    <ModalLayout isOpen={true} size="md" centered>
      <ModalBody>
        <div className="request_placed_successful_modal_body_container">
          <div className="request_placed_successful_img_container">
            <img src={SuccessGif} alt="success-gif" />
          </div>

          <h5 className="request_placed_successful_heading">
            Your request has been submitted successfully
          </h5>
        </div>
      </ModalBody>
    </ModalLayout>
  );
}

export default RequestPlacedSuccessfulModal;
