// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --white: #ffffff;
    --black: #000000;
    --primary: #001426;
    --secondary: #ddebf1;
    --error: #eb4335;
    --disable: #e9ecef;
    --form-mandatory-color: #eb4146;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;IAClB,+BAA+B;AACnC","sourcesContent":[":root {\n    --white: #ffffff;\n    --black: #000000;\n    --primary: #001426;\n    --secondary: #ddebf1;\n    --error: #eb4335;\n    --disable: #e9ecef;\n    --form-mandatory-color: #eb4146;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
