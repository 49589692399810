import AppRoutes from "./app-routes";

export default class DashboardConstants {
  static createVendorBidderMenus(includeDashboard = false) {
    const routes = [
      { label: "All Request", path: AppRoutes.VENDOR_BIDDER_ACTIVE_REQUESTS },
      { label: "New Request", path: AppRoutes.VENDOR_BIDDER_NEW_REQUESTS },
      {
        label: "Interested Request",
        path: AppRoutes.VENDOR_BIDDER_INTERESTED_REQUESTS,
      },
      {
        label: "Bids In Progress",
        path: AppRoutes.VENDOR_BIDDER_BIDS_IN_PROGRESS,
      },
      { label: "Raised Bids", path: AppRoutes.VENDOR_BIDDER_RAISED_BIDS },
      {
        label: "Purchase Order Received",
        path: AppRoutes.VENDOR_BIDDER_PURCHASE_ORDER_RECEIVED,
      },
      {
        label: "Work In Progress",
        path: AppRoutes.VENDOR_BIDDER_WORK_IN_PROGRESS,
      },
      {
        label: "Completed Jobs",
        path: AppRoutes.VENDOR_BIDDER_RAISED_COST_DISCOUNT,
      },
      {
        label: "Invoice Raised",
        path: AppRoutes.VENDOR_BIDDER_COMPLETED_INVOICE,
      },
    ];

    return includeDashboard
      ? [
          { label: "Dashboard", path: AppRoutes.VENDOR_BIDDER_DASHBOARD },
          ...routes,
        ]
      : routes;
  }

  static createCustomerRequestorMenus(includeDashboard = false) {
    const routes = [
      {
        label: "All Request",
        path: AppRoutes.CUSTOMER_REQUESTOR_ACTIVE_REQUESTS,
      },
      {
        label: "Open Request",
        path: AppRoutes.CUSTOMER_REQUESTOR_OPEN_REQUESTS,
      },
      {
        label: "Response To Requests",
        path: AppRoutes.CUSTOMER_REQUESTOR_PURCHASE_ORDERS,
      },
      {
        label: "Purchase Order In Progress",
        path: AppRoutes.CUSTOMER_REQUESTOR_WORK_IN_PROGRESS,
      },
      {
        label: "Work In Progress",
        path: AppRoutes.CUSTOMER_REQUESTOR_APPROVER_ADD_COST_DISCOUNT,
      },
      {
        label: "Completed Jobs",
        path: AppRoutes.CUSTOMER_REQUESTOR_COMPLETED_JOBS,
      },
      {
        label: "Invoice Raised",
        path: AppRoutes.CUSTOMER_REQUESTOR_INVOICE_RAISEDS,
      },
      {
        label: "Requests With No Response",
        path: AppRoutes.CUSTOMER_REQUESTOR_REQUESTED_WITH_NO_RESPONSE,
      },
      { label: "Archive", path: AppRoutes.CUSTOMER_REQUESTOR_ARCHIVE },
    ];

    return includeDashboard
      ? [
          { label: "Home", path: AppRoutes.CUSTOMER_REQUESTOR_DASHBOARD },
          ...routes,
        ]
      : routes;
  }

  static VendorBidderMenus = DashboardConstants.createVendorBidderMenus(true);
  static VendorBidderDashboard =
    DashboardConstants.createVendorBidderMenus(false);

  static CustomerRequestorMenus =
    DashboardConstants.createCustomerRequestorMenus(true);
  static CustomerRequestorDashboard =
    DashboardConstants.createCustomerRequestorMenus(false);
}
