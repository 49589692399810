import RegexUtils from "./regex/regex-utils";
import ToastUtils from "./toast/toast-utils";

export default class UtilityFunc {
  static isValidEmail = (string) => {
    if (string === "") {
      return true;
    } else {
      return RegexUtils.emailRegex.test(string);
    }
  };

  static isNumber = (value) => {
    return typeof value === "number" && !isNaN(value);
  };

  static isValidNumber = (string) => {
    if (string === "") {
      return true;
    } else {
      return RegexUtils.onlyNumbers.test(string);
    }
  };

  static isValidAplha = (string) => {
    if (string === "") {
      return true;
    } else {
      return RegexUtils.onlyAlphabetsAllCase.test(string);
    }
  };

  static isValidAlphaNumberic = (string) => {
    if (string === "") {
      return true;
    } else {
      return RegexUtils.alphaNumeric.test(string);
    }
  };

  static isValidAlphaNumbericWithoutSpace = (string) => {
    if (string === "") {
      return true;
    } else {
      return RegexUtils.alphaNumericWithoutSpace.test(string);
    }
  };

  static isValidPassword = (string) => {
    return RegexUtils.passwordRegex.test(string);
  };

  static thousandSeparator = (num) => {
    return num.toString().replace(RegexUtils.thousandSeparator, ",");
  };

  static removeNonNumeric = (num) => {
    // return num.toString().replace(/[0-9]/g, "");
    return /^\d*\.?\d*$/.test(`${num}`);
  };

  static removeAlpha = (num) => {
    return num.toString().replace(/[A-Za-z]/g, "");
  };

  static isANumber = (n) => {
    let numStr = /^-?(\d+\.?\d*)$|(\d*\.?\d+)$/;
    let containsNonNumeric = /\D/.test(n);
    return numStr.test(n.toString()) && !containsNonNumeric;
  };

  static fileSizeValidation = (file, size) => {
    if (file?.size > size * 1024 * 1024) {
      ToastUtils?.showErrorToast("File size too big or incorrect format.");
      return false;
    } else {
      return true;
    }
  };

  static areMobileAndEmailUnique = (data) => {
    const uniqueMobileNumbers = new Set();
    const uniqueEmailAddresses = new Set();

    for (const entry of data) {
      const { mobileNumber, emailAddress } = entry;

      if (mobileNumber) {
        if (uniqueMobileNumbers.has(mobileNumber)) {
          ToastUtils.showErrorToast("Duplicate mobileNumber found.");
          return false;
        }
        uniqueMobileNumbers.add(mobileNumber);
      }

      if (emailAddress) {
        if (uniqueEmailAddresses.has(emailAddress)) {
          ToastUtils.showErrorToast("Duplicate emailAddress found.");
          return false;
        }
        uniqueEmailAddresses.add(emailAddress);
      }
    }
    return true;
  };
}
