import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import HttpClient from './../../../../../config/http-client.js';
import { baseUrl, headers, authToken } from '../../../../../config/index.js';
import { GET_VENDOR_FINANCIERS, GET_VENDOR_FINANCIERS_SUCCESS, GET_VENDOR_FINANCIERS_ERROR } from '../../../actionTypes.js';

function* getVendorFinanciers() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/todos/1`,
      method: 'GET',
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_VENDOR_FINANCIERS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VENDOR_FINANCIERS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VENDOR_FINANCIERS_ERROR, payload: error });
  }
}

export function* watchGetVendorFinanciers() {
  yield takeEvery(GET_VENDOR_FINANCIERS, getVendorFinanciers);
}

function* getVendorFinanciersSaga() {
  yield all([fork(watchGetVendorFinanciers)]);
}

export default getVendorFinanciersSaga;
