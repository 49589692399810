import React from "react";
import { MutatingDots } from "react-loader-spinner";

const DashboardLoader = () => {
  return (
    <React.Fragment>
      <div className="dashboard-loader">
        <div className="dashboard-loader-icon-container">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#d25b30"
            secondaryColor="#d25b30"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardLoader;
