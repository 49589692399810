import React from 'react';
import RequestorLayoutWrapper from '../RequestorLayout';

export default function RequestorArchive() {
  return (
    <RequestorLayoutWrapper>
      <div>Archive</div>
    </RequestorLayoutWrapper>
  );
}
