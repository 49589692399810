import { ModalBody } from "reactstrap";

import SuccessGif from "../../../assets/images/dashboard/success.gif";
import ModalLayout from "../Modal";

function SuccessfullModal({ Msg, onClick, name, size = "md" }) {
  return (
    <ModalLayout isOpen={true} size={size} centered>
      <ModalBody>
        <div className="request_placed_successful_modal_body_container">
          <div className="request_placed_successful_img_container">
            <img src={SuccessGif} alt="success-gif" />
          </div>

          <h5 className="request_placed_successful_heading">{<Msg />}</h5>
        </div>
      </ModalBody>
    </ModalLayout>
  );
}

export default SuccessfullModal;
