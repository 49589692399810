import React from 'react';
import DashboardLayoutWrapper from '../BidderLayout';

export default function WorkInProgress() {
  return (
    <DashboardLayoutWrapper>
      <div>
        Work in Progress
      </div>
    </DashboardLayoutWrapper>
  );
}
