import { Modal } from "reactstrap";

function ModalLayout({ children, ...props }) {
  return (
    <Modal isOpen={true} {...props}>
      {children}
    </Modal>
  );
}

export default ModalLayout;
