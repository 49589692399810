import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayoutWrapper from "../BidderLayout";
import ViewContainer from "../../../../../components/common/ViewContainer";
import Heading from "../../../../../components/common/Heading";
import { Col, Row } from "reactstrap";
import HorizontalLine from "../../../../../components/common/HorizontalLine";
import ViewLabel from "../../../../../components/common/ViewLabel";
import TextValue from "../../../../../components/common/TextValue";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  GET_SERVICE_REQUEST_DETAIL,
  UPDATE_SERVICE_REQUEST_STATUS,
  UPDATE_SERVICE_REQUEST_STATUS_EMPTY,
} from "../../../../../store/apis/actionTypes";
import {
  DashboardLoader,
  PrimaryButton,
} from "../../../../../components/common";
import DateUtils from "../../../../../utils/date-utils";
import { AppRoutes } from "../../../../../components/constants";
import ModalConfirmation from "../../../../../components/common/ModalConfirmation";
import { FaRegFileAlt } from "react-icons/fa";

const InterestedServiceRequestDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const requestId = query.get("requestId");

  const [isPrepareBids, setIsPrepareBids] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { requestDetails, loading, usrLoading, serviceRequestData } =
    useSelector((state) => ({
      requestDetails: state?.GetServiceRequestDetail?.data,
      loading: state?.GetServiceRequestDetail?.loading,
      usrLoading: state?.UpdateServiceRequestStatus?.loading,
      serviceRequestData: state?.UpdateServiceRequestStatus?.data,
    }));

  useEffect(() => {
    if (serviceRequestData !== null) {
      dispatch({
        type: UPDATE_SERVICE_REQUEST_STATUS_EMPTY,
      });
      setIsPrepareBids(true);
    }
  }, [serviceRequestData, dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_SERVICE_REQUEST_DETAIL,
      payload: { requestId },
    });
  }, [dispatch, requestId]);
  const dummyImageUrls = [
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/200",
    "https://via.placeholder.com/200",
    "https://via.placeholder.com/200",

    "https://via.placeholder.com/200",
    "https://via.placeholder.com/200",
  ];
  const updateServiceRequest = () => {
    setIsPrepareBids(true);
  };

  useEffect(() => {
    const loadingStates = [usrLoading, loading];
    const anyLoading = loadingStates.some((loading) => loading);
    setIsLoading(anyLoading);
  }, [usrLoading, loading]);

  const showMsg = () => {
    return (
      <>
        <span>Rids have alreadv been aiven for this request ID</span> <br />
        <span>A fresh bid will be submitted</span>
        <span>
          only if there is a difference of at least 25% between the submitted
          bid and the new bid
        </span>
      </>
    );
  };

  const handleProceed = () => {
    setIsPrepareBids(false);
    navigate(AppRoutes.VENDOR_BIDDER_INTERESTED_BREAK_UPS);
  };

  return (
    <DashboardLayoutWrapper>
      {isLoading && <DashboardLoader />}
      {isPrepareBids && (
        <ModalConfirmation
          msg={showMsg()}
          size="md"
          onProceed={() => handleProceed()}
          onCancel={() => setIsPrepareBids(false)}
        />
      )}

      <ViewContainer>
        <Heading>Vessel Details</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Vessel Name</ViewLabel>
            <TextValue>{requestDetails?.vesselDetail?.name || "N/A"}</TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Vessel Type</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.vesselType?.name || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>IMO Number</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.imoNumber || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Vessel Flag</ViewLabel>
            <TextValue>
              {requestDetails?.vesselDetail?.vesselFlag?.name || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />
        <Heading>Service Request At</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Country</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.country?.name || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Port</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.port?.name || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Location</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.location?.name || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ViewLabel>E.T.A (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.eta
              ) || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>E.T.D (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.etd
              ) || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Service Required On (Local Time)</ViewLabel>
            <TextValue>
              {DateUtils?.formatDateObject(
                requestDetails?.serviceRequestAt?.serviceRequestOn
              ) || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ViewLabel>Bid Send To Email ID</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.bidSendToEmailAddress || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Bid Currency</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestAt?.country?.currency || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />
        <Heading>Service Request Details</Heading>
        <Row>
          <Col lg={4}>
            <ViewLabel>Equipment Category</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.equipmentCategory?.name ||
                "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Equipment Name</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.equipment?.name || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Manufacturer</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.make?.name || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ViewLabel>Model</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.model || "N/A"}
            </TextValue>
          </Col>
          <Col lg={4}>
            <ViewLabel>Issue Type</ViewLabel>
            <TextValue>
              {requestDetails?.serviceRequestDetail?.issueType || "N/A"}
            </TextValue>
          </Col>
        </Row>
        <HorizontalLine />
        <Row>
          <Col lg={12}>
            <ViewLabel>Description of Problem / Service Required</ViewLabel>
            <TextValue>{requestDetails?.serviceDescription || "N/A"}</TextValue>
          </Col>
        </Row>
        <HorizontalLine />
        <Heading>Attachments</Heading>
        <Row>
          {dummyImageUrls.length > 0 ? (
            dummyImageUrls.map((file, index) => (
              <Col
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={index}
                className="d-flex justify-content-start mb-4"
              >
                {/* <img
                  src={file}
                  alt={`document ${index}`}
                  className="img-fluid rounded"
                /> */}

                <span>
                  <FaRegFileAlt />
                </span>
              </Col>
            ))
          ) : (
            <TextValue>No Attachments Available</TextValue>
          )}
        </Row>

        {/* <HorizontalLine />
        <Row>
          <Col lg={4}>
            <ViewLabel>Bid (quotes) needed in</ViewLabel>
            <TextValue>{requestDetails?.bidTime || "N/A"}</TextValue>
          </Col>
        </Row> */}
      </ViewContainer>

      <div className="d-flex justify-content-end">
        <PrimaryButton
          name={"Prepare Bid"}
          onClick={updateServiceRequest}
        ></PrimaryButton>
      </div>
    </DashboardLayoutWrapper>
  );
};

export default InterestedServiceRequestDetails;
