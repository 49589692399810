import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import HttpClient from '../../../../config/http-client.js';
import { baseUrl, headers, authToken } from '../../../../config/index.js';
import { VENDOR_REGISTRATION, VENDOR_REGISTRATION_SUCCESS, VENDOR_REGISTRATION_ERROR } from '../../actionTypes.js';

function* vendorSignup({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/admin/bookings`,
      method: 'POST',
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: VENDOR_REGISTRATION_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: VENDOR_REGISTRATION_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_REGISTRATION_ERROR, payload: error });
  }
}

export function* watchVendorSignup() {
  yield takeEvery(VENDOR_REGISTRATION, vendorSignup);
}

function* vendorSignupSaga() {
  yield all([fork(watchVendorSignup)]);
}

export default vendorSignupSaga;
