// import { toast } from "react-toastify";
// export default class ToastUtils {
//   static showSuccessToast = (msg) => {
//     toast.success(msg, {
//       position: "top-center",
//       hideProgressBar: true,
//       toastId: msg,
//       style: {
//         color: "black",
//         background: "white",
//       },
//     });
//   };

//   static showErrorToast = (msg) => {
//     toast.error(msg, {
//       position: "top-center",
//       hideProgressBar: true,
//       toastId: msg,
//       style: {
//         color: "white",
//         background: "rgb(150,20,77)",
//       },
//     });
//   };
//   static showInfoToast = (msg) => {
//     toast.info(msg, {
//       position: "top-center",
//       hideProgressBar: true,
//       toastId: msg,
//       style: {
//         color: "white",
//         background: "rgb(150,20,77)",
//       },
//     });
//   };
// }

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FiX, FiCheck } from "react-icons/fi";
import { AiOutlineInfo } from "react-icons/ai";

const ToastContent = ({ heading, subheading, icon }) => (
  <div className="toast-content-container">
    <div className="toast-icon-container">{icon}</div>
    <div>
      <h4 className="custom-toast-heading">{heading}</h4>
      {subheading && <p className="custom-toast-body">{subheading}</p>}
    </div>
  </div>
);

export default class ToastUtils {
  static showSuccessToast = (msgOrHeading, subMsg) => {
    const heading = subMsg ? msgOrHeading : "Success";
    const subheading = subMsg ? subMsg : msgOrHeading;
    toast.success(
      <ToastContent
        heading={heading}
        subheading={subheading}
        icon={
          <div className="success-toast-icon-container">
            <span className="toast-icon">
              <FiCheck />
            </span>
          </div>
        }
      />,
      {
        position: "top-right",
        hideProgressBar: true,
        className:
          "custom-toast-success-container custom-toast-media-container",
        toastId: subheading,
      }
    );
  };

  static showErrorToast = (msgOrHeading, subMsg) => {
    const heading = subMsg ? msgOrHeading : "Error";
    const subheading = subMsg ? subMsg : msgOrHeading;
    toast.error(
      <ToastContent
        heading={heading}
        subheading={subheading}
        icon={
          <div className="error-toast-icon-container">
            <span className="toast-icon">
              <FiX />
            </span>
          </div>
        }
      />,
      {
        position: "top-right",
        hideProgressBar: true,
        className: "custom-toast-error-container custom-toast-media-container",
        toastId: subheading,
      }
    );
  };

  static showInfoToast = (msgOrHeading, subMsg) => {
    const heading = subMsg ? msgOrHeading : "Info";
    const subheading = subMsg ? subMsg : msgOrHeading;
    toast.info(
      <ToastContent
        heading={heading}
        subheading={subheading}
        icon={
          <div className="info-toast-icon-container">
            <span className="toast-icon">
              <AiOutlineInfo />
            </span>
          </div>
        }
      />,
      {
        position: "top-right",
        hideProgressBar: true,
        className: "custom-toast-info-container custom-toast-media-container",
        toastId: subheading,
      }
    );
  };

  static showWarningToast = (msgOrHeading, subMsg) => {
    const heading = subMsg ? msgOrHeading : "Warning";
    const subheading = subMsg ? subMsg : msgOrHeading;
    toast.info(
      <ToastContent
        heading={heading}
        subheading={subheading}
        icon={
          <div className="warning-toast-icon-container">
            <span className="toast-icon">
              <AiOutlineInfo />
            </span>
          </div>
        }
      />,
      {
        position: "top-right",
        hideProgressBar: true,
        className:
          "custom-toast-warning-container custom-toast-media-container",
        toastId: subheading,
      }
    );
  };
}
