import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import HttpClient from "../../../../config/http-client.js";
import { baseUrl, authToken } from "../../../../config/index.js";
import {
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from "../../actionTypes.js";

function* uploadFile(action) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/file/v1/upload`,
      method: "POST",
      headers: {
        Authorization: authToken,
      },
      data: action.payload,
      isFormData: true,
    });

    if (response?.status === 200) {
      yield put({
        type: UPLOAD_FILE_SUCCESS,
        payload: response?.data.path,
      });
    } else {
      yield put({
        type: UPLOAD_FILE_ERROR,
        payload: response?.data?.message || "Failed to upload the file.",
      });
    }
  } catch (error) {
    yield put({
      type: UPLOAD_FILE_ERROR,
      payload: error.message || "File upload failed due to a server error.",
    });
  }
}

export function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE, uploadFile);
}

function* fileUploadSaga() {
  yield all([fork(watchUploadFile)]);
}

export default fileUploadSaga;
