import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./apis/reducers";
import rootSaga from "./apis/sagas";
import { logger } from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  if ([`dev`, `staging`]?.includes(process.env.REACT_APP_NODE_ENV)) {
    middlewares.push(logger);
  }

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
    // composeEnhancers(applyMiddleware(...middlewares, logger)),
  );
  sagaMiddleware.run(rootSaga);
  return store;
}
