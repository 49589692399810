import React from 'react';
import RequestorLayoutWrapper from '../RequestorLayout';

export default function RequestorRequestedWithNoResponse() {
  return (
    <RequestorLayoutWrapper>
      <div>Requested with No Response</div>
    </RequestorLayoutWrapper>
  );
}
