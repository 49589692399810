export default class AppConstants {
  static MOBILE_SCREEN_WIDTH = 768;
  static CUSTOMER_CARE_MAIL = 'customer.care@athmarine.com';
  static CUSTOMER_CARE_NUMBER = '+971 45177071';
  static COUNTDOWN_TIMER = 90;
  static UAR_COUNTRY_ID = '971';
  static APP_TIMEZONE = 'Asia/Dubai';

  static TABLE_PAGE_SIZE = 10;
}
