import { Link, useLocation } from "react-router-dom";

export default function Breadcrumb({
  path = "/vendor/dashboard/bidder",
  content = null,
}) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  pathnames.splice(0, 3);

  const segments = [
    { label: "Dashboard", path: path },
    ...pathnames.map((segment, index) => ({
      label: toTitleCase(segment.replace(/-/g, " ")),
      path: `/${pathnames.slice(0, index + 1).join("/")}`,
    })),
  ];

  function toTitleCase(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <>
      <nav aria-label="breadcrumb" className="breadcrumb-navigation">
        <ol className="breadcrumb">
          {segments.map((segment, index) => (
            <li
              key={segment.path}
              // className={`breadcrumb-item${index === segments.length - 1 ? ' active' : ''}`}
              aria-current={index === segments.length - 1 ? "page" : undefined}
            >
              {index === segments.length - 1 ? (
                segment.label
              ) : (
                <>
                  <Link to={segment.path}>{segment.label}</Link>
                  <span className="mx-1">{">"}</span>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>

      <div className="breadContainer">
        <div className="currentPage">{segments[segments.length - 1].label}</div>
        <div>{content}</div>
      </div>
    </>
  );
}
