import { Footer, Header } from '../../../components/common';

export default function FAQS() {
  window.scroll({
    top: 0,
    left: 0,
  });

  return (
    <>
      <Header />
      <main className="section_faqs">
        <section className="faqs_container">
          <h2 className="faqs_heading">FAQ&#39;S</h2>

          <div className="faqs_body">
            <h1 className="faqs_text">We will update soon!</h1>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
