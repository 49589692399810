import LocalStorageConstants from "./local-storage-constants";

export default class LocalStorageUtils {
  static getFromLocalStorage = (key) => {
    let item = localStorage.getItem(key);
    if (item == null || item == "undefined") {
      return null;
    }
    let setting = JSON.parse(item);
    if (setting == null) {
      return null;
    }
    return setting;
  };

  static saveToLocalStorage = (key, value) => {
    if (key == null || key === "") {
      return null;
    }
    localStorage.setItem(key, JSON.stringify(value));
  };

  static clearData = () => {
    LocalStorageUtils.saveToLocalStorage(
      LocalStorageConstants.IS_LOGGED_IN,
      false
    );
    LocalStorageUtils.saveToLocalStorage(
      LocalStorageConstants.AUTH_TOKEN,
      null
    );
  };
}
