import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import { FiX } from "react-icons/fi";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ToastContainer
          // transition={Slide}
          limit={3}
          autoClose={3000}
          closeButton={
            <span className="toast-close-icon-container">
              <FiX />
            </span>
          }
        />
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

reportWebVitals();
