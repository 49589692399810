import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DashboardLoader, Table } from "../../../../../components/common";
import Drawer from "../../../../../components/common/Drawer";
import FilterRefresh from "../../../../../components/common/FilterRefresh";
import { ServiceRequestStatuses } from "../../../../../enums/utils-enums";
import {
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_EMPTY,
} from "../../../../../store/apis/actionTypes";
import DateUtils from "../../../../../utils/date-utils";
import DashboardLayoutWrapper from "../BidderLayout";

export default function BidderOpenRequests() {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [perPageLimit, setPerPageLimit] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // REQUESTS
  const { serviceRequests, serviceRequestsLoading } = useSelector((state) => ({
    serviceRequests: state?.GetServiceRequests?.data,
    serviceRequestsLoading: state?.GetServiceRequests?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_SERVICE_REQUESTS,
      payload: {
        params: {
          status: ServiceRequestStatuses?.OPEN,
          page: currentPageNumber,
          size: perPageLimit,
        },
      },
    });

    return () => {
      dispatch({
        type: GET_SERVICE_REQUESTS_EMPTY,
      });
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);

    dispatch({
      type: GET_SERVICE_REQUESTS,
      payload: {
        params: {
          status: ServiceRequestStatuses?.OPEN,
          page: page,
          size: perPageLimit,
        },
      },
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    setCurrentPageNumber(page);
    setPerPageLimit(newRowsPerPage);

    dispatch({
      type: GET_SERVICE_REQUESTS,
      payload: {
        params: {
          status: ServiceRequestStatuses?.OPEN,
          page: page,
          size: newRowsPerPage,
        },
      },
    });
  };

  const handleRefresh = () => {
    dispatch({
      type: GET_SERVICE_REQUESTS,
      payload: {
        params: {
          status: ServiceRequestStatuses?.OPEN,
          page: currentPageNumber,
          size: perPageLimit,
        },
      },
    });
  };

  const handleRowClick = (row) => {
    console.log("Row clicked:", row);
    // Handle the row click logic here, e.g., navigate to a detail page
  };

  return (
    <DashboardLayoutWrapper
      breadCrumbContent={
        <FilterRefresh onFilter={toggleDrawer} onRefresh={handleRefresh} />
      }
    >
      {serviceRequestsLoading && <DashboardLoader />}

      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
      <Table
        data={[
          ...(serviceRequests?.result ?? [])?.map((data, index) => ({
            ...data,
            serialNo: `${
              perPageLimit * currentPageNumber - perPageLimit + index + 1
            }.`,
          })),
        ]}
        limit={perPageLimit}
        totalCount={serviceRequests?.count}
        columns={columns}
        currentPage={currentPageNumber}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        onRowClick={handleRowClick}
      />
    </DashboardLayoutWrapper>
  );
}

const columns = [
  {
    name: "S.No",
    selector: (row) => <span className="fw-bold">{row?.serialNo}</span>,
    sortable: false,
    width: "90px",
  },
  {
    name: "Request ID",
    selector: (row) => row?.serviceRequestId,
    width: "150px",
  },
  {
    name: "Category",
    selector: (row) => row?.equipmentCategoryName,
    width: "150px",
  },
  {
    name: "Vessel",
    selector: (row) => row?.vesselName,
    width: "150px",
  },
  {
    name: "Country",
    selector: (row) => row?.countryName,
    width: "150px",
  },
  {
    name: "ETA",
    selector: (row) => DateUtils?.formatDateObject(row?.eta),
    width: "200px",
  },
  {
    name: "ETD",
    selector: (row) => DateUtils?.formatDateObject(row?.etd),
    width: "200px",
  },
  {
    name: "Service Request On",
    selector: (row) => DateUtils?.formatDateObject(row?.serviceRequestOn),
    width: "200px",
  },
];
