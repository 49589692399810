import { ApiConstants } from "../components/constants/index";
import LocalStorageUtils from "../utils/storage/local-storage/local-storage-utils";
import LocalStorageConstants from "../utils/storage/local-storage/local-storage-constants";

/* ---------------------------- COMMON ---------------------------- */
const headers = {
  "Content-Type": "application/json",
};

// API base URL
const baseUrl = ApiConstants?.SERVER_URL;

// API base URL
const authToken = LocalStorageUtils.getFromLocalStorage(
  LocalStorageConstants?.AUTH_USER
)?.token;

export { baseUrl, headers, authToken };
