import React from 'react';
import RequestorLayoutWrapper from '../RequestorLayout';

export default function RequestorApproverAddCostDiscount() {
  return (
    <RequestorLayoutWrapper>
      <div>Approver Add. Cost / Discount</div>
    </RequestorLayoutWrapper>
  );
}
