import React from "react";

import Sidebar from "../../../../../components/common/Sidebar";
import {
  AppRoutes,
  DashboardConstants,
} from "../../../../../components/constants";
import { Footer, Header } from "./../../../../../components/common/index";
import Breadcrumb from "../../../../../components/common/Breadcrumb";

export default function DashboardLayoutWrapper({ children }) {
  return (
    <div className="layout-wrapper">
      <Header />
      <main className="d-flex">
        <Sidebar sidebar={DashboardConstants?.VendorBidderMenus} />
        <section className="main-area position-relative dashboard-container ">
          <Breadcrumb path={AppRoutes.VENDOR_BIDDER_DASHBOARD} />
          {children}
        </section>
      </main>
      <Footer />
    </div>
  );
}
