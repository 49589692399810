import {
  GET_VESSEL_FLAGS,
  GET_VESSEL_FLAGS_SUCCESS,
  GET_VESSEL_FLAGS_ERROR,
  GET_VESSEL_FLAGS_EMPTY,
} from "../../actionTypes";

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const VesselFlags = (state = initialState, action) => {
  switch (action.type) {
    case GET_VESSEL_FLAGS:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_VESSEL_FLAGS_SUCCESS:
      state = {
        ...state,
        data: action?.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case GET_VESSEL_FLAGS_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case GET_VESSEL_FLAGS_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VesselFlags;
