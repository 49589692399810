import { useNavigate } from "react-router-dom";

import { AppRoutes } from "../../constants";
import AthMarineLogo from "./../../../assets/images/AthMarineLogo.png";

const navItems = [
  {
    name: `Terms & services`,
    path: AppRoutes.TERM_SERVICES,
  },
  {
    name: `Privacy policies`,
    path: AppRoutes.PRIVACY_POLICY,
  },
  {
    name: `Rules & guidelines`,
    path: AppRoutes.RULES_GUIDELINES,
  },
  {
    name: `FAQs`,
    path: AppRoutes.FAQS,
  },
  {
    name: `Contact Us`,
    path: AppRoutes.CONTACT_US,
  },
];

export default function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="section-footer">
        <div className="logo-container">
          <div className="logo-wall">
            <img src={AthMarineLogo} className="logo" alt="logo" />
          </div>
        </div>

        <nav>
          <ul className="list-item-container">
            {navItems?.map((navItem, index) => (
              <li key={index}>
                <span onClick={() => navigate(navItem?.path)}>
                  {navItem?.name}
                </span>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className="horizontalLine">
        <div className="line"></div>
      </div>

      <div className="copyright">
        <span className="copyrightText">
          © {new Date().getFullYear()} AthMarine. All rights reserved
        </span>
      </div>
    </footer>
  );
}
