import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import Calendar from "../../../../assets/images/calendar.png";

export default function DateField({ ...props }) {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          right: "4%",
          top: "47%",
          pointerEvents: "none",
          transform: "translate(4%,-47%)",
          width: "5%",
        }}
      >
        <img
          src={Calendar}
          alt="icon-calendar"
          style={{
            height: "100%",
            width: "100%",
            pointer: "none",
          }}
        />
      </div>

      <Flatpickr
        options={{ minDate: "2017-01-01" }}
        className="textField"
        {...props}
      />
    </div>
  );
}
