export default class RegexUtils {
  static passwordRegex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/m;

  static emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static mobileRegex = /^\d{8,10}$/i;

  static onlyNumbers = /^\d+$/;

  static onlyAlphabetsAllCase = /^[A-Za-z ]+$/;

  static alphaNumeric = /^[a-z0-9]+$/i;

  static otpRegex = /^\d{6}$/i;
}
