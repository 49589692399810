import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* getDashboardData() {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/todos/1`,
      method: "GET",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response?.status === 200) {
      yield put({
        type: GET_DASHBOARD_DATA_SUCCESS,
        payload: response?.data,
      });
    } else {
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: GET_DASHBOARD_DATA_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_DASHBOARD_DATA_ERROR, payload: error });
  }
}

export function* watchGetDashboardData() {
  yield takeEvery(GET_DASHBOARD_DATA, getDashboardData);
}

function* getDashboardDataSaga() {
  yield all([fork(watchGetDashboardData)]);
}

export default getDashboardDataSaga;
