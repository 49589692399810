import React from 'react'

const RadioField = (props) => {
  const { changed, id, name, isSelected, label, value, disabled = false } = props

  return (
    <div className="radioField">
      <input
        id={id}
        name={name}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
        disabled={disabled}
      />

      <label className="form_label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default RadioField
