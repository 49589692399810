import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import HttpClient from "./../../../../../../config/http-client.js";
import { baseUrl, headers, authToken } from "../../../../../../config/index.js";
import {
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_REQUEST_SUCCESS,
  CREATE_SERVICE_REQUEST_ERROR,
} from "../../../../actionTypes.js";
import ToastUtils from "../../../../../../utils/toast/toast-utils.js";

function* createServiceRequest({ payload }) {
  try {
    const response = yield call(HttpClient, {
      url: `${baseUrl}/service/v1/create-request`,
      method: "POST",
      headers: {
        Authorization: authToken,
        ...headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_SERVICE_REQUEST_SUCCESS,
        payload: response?.data,
      });
    } else {
      // ToastUtils.showSuccessToast("Welcome");
      ToastUtils?.showErrorToast(`${response?.response?.data?.message}`);
      yield put({
        type: CREATE_SERVICE_REQUEST_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_SERVICE_REQUEST_ERROR, payload: error });
  }
}

export function* watchCreateServiceRequest() {
  yield takeEvery(CREATE_SERVICE_REQUEST, createServiceRequest);
}

function* createServiceRequestSaga() {
  yield all([fork(watchCreateServiceRequest)]);
}

export default createServiceRequestSaga;
