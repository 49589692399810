import { ModalBody } from "reactstrap";
import {
  ModalLayout,
  PrimaryButton,
  SecondaryButton,
} from "../../../../../components/common";

function ConfirmationModal({ onProceed, onCancel }) {
  return (
    <ModalLayout isOpen={true} size="lg" centered>
      <ModalBody>
        <div className="service_request_confirmation_modal_body_container">
          <h5 className="service_request_heading">
            You are requesting quotation in the Urgent / Top urgent category,
            given the time we have in hand you may get a low response.
          </h5>

          <h5 className="service_request_heading_confirmation">
            Are you sure you want to proceed?
          </h5>

          <div className="service_request_btn_container">
            <SecondaryButton name="Cancel" onClick={onCancel} />
            <PrimaryButton name="Proceed" onClick={onProceed} />
          </div>
        </div>
      </ModalBody>
    </ModalLayout>
  );
}

export default ConfirmationModal;
