export default function FileInput({ disabled, onChange }) {
  return (
    <div className="mb-3">
      <input type="file" disabled={disabled} className="form-control" id="customFile" style={{ display: 'none' }} onChange={onChange} />
      <label className="custom-btn" htmlFor="customFile">
        Upload Files
      </label>
    </div>
  );
}
